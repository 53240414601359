import {createStore} from 'vuex'
import VuexPersistence from 'vuex-persist'
import router from '../router/'

const storage = createStore({
    state:{
        isAuthenticated: false,
    },
    mutations:{
        setAuthenticated(state, value){
            state.isAuthenticated = value;
        },
        logout(state){
            state.isAuthenticated = false;
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            router.push('/Login');
            window.location.reload();
        }
    },
    plugins:[
        new VuexPersistence({
            storage:window.localStorage,
        }).plugin
    ]
});

export default storage
