<template>
    <div class="container-fluid h-100">
        <div class="h-100 d-flex flex-column">
            <div class="row align-items-center justify-content-between px-0 g-0 mb-2">
                <div class="col-auto">
                    <h4>Estadísticas</h4>
                </div>

                <div class="col-auto row px-0">
                    <div class="col-auto">
                        <div class="dropdown" v-show="vehiculoSeleccionado">
                            <button class="btn btn-sm btn-light btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">{{vistas[vista]}}</button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                  <li class="p-2" v-for="(v,index) in vistas" :key="v" @click="changeVista(index)">{{v}}</li>
                                </ul>
                        </div>
                    </div>
                    <div class="col-auto">
                        <button type="button" class="btn btn-success btn-sm mt-auto" @click="openListaVehiculos()">Seleccionar vehículo...</button>
                    </div>
                </div>
            </div>


            <div v-show="loading" class="over-layed">
                <div class="spinner-border spinner-lg" role="status"></div>
            </div>

            <div class="row g-0 justify-content-between rounded p-2" v-show="vehiculoSeleccionado" style="background-color: lightGrey;">

                <div class="col-auto">
                    <h5 class="mb-0">
                        {{vehiculoSeleccionado?(vehiculoSeleccionado.tipo_vehiculo + '  ' + vehiculoSeleccionado.marca + '    ' + vehiculoSeleccionado.modelo):''}}
                    </h5>
                </div>
                <div class="col-auto">
                    <h5 class="mb-0">{{vehiculoSeleccionado?'No. económico ' + vehiculoSeleccionado.no_economico:''}}</h5>
                </div>
                <div class="col-auto">
                    <h5 class="mb-0">{{vehiculoSeleccionado?'Placa estatal: ' + vehiculoSeleccionado.placa_estatal:''}}</h5>
                </div>
                <div class="col-auto">
                    <h5 class="mb-0">{{vehiculoSeleccionado?(vehiculoSeleccionado.placa_federal?'Placa federal: ' + vehiculoSeleccionado.placa_federal:''):''}}</h5>
                </div>
            </div>

            <div class="flex-grow-1 flex-column d-flex bg-white shadow p-2 mt-4" v-if="vehiculoSeleccionado">
                <div v-if="vista===2">
                    <div class="row align-items-center justify-content-between px-0 g-0 my-2">
                        <div class="col-auto">
                            <h5>Movimientos</h5>
                        </div>

                        <div class="col-auto" v-show="totalPaginas>1"> 
                            <nav aria-label="..." > 
                                <ul class="pagination pagination-sm justify-content-end mb-0">  
                                    <li v-bind:class="{'disabled':vistaActual==1 , 'page-item': true} "> 
                                      <a class="page-link" href="javascript:;" @click="vistaActual--"><i class="bi bi-chevron-double-left"></i></a> 
                                    </li>   
                                    <li v-for="pagina in totalVistas" :key="pagina.id" v-bind:class="{'active': pagina+(paginasVista*(vistaActual-1))==paginaActual, 'page-item':true} "><a class="page-link" href="javascript:;"   @click="paginaActual = pagina+(paginasVista*(vistaActual-1))">{{pagina+(paginasVista*(vistaActual-1))}}</a></li>
                                    <li v-bind:class="{'disabled':vistaActual >= (totalPaginas/paginasVista) , 'page-item': true}">
                                      <a class="page-link" href="javascript:;" @click="vistaActual++"><i class="bi bi-chevron-double-right"></i></a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <div class="row table-responsive g-0">
                        <table class="table table-sm table-striped table-bordered table-hover mb-0" style="white-space: nowrap;font-size:.9rem;">
                            <thead> 
                                <th scope="col" v-for="header in tableHeaders" :key="header">{{header[1]}}</th>
                            </thead>    
                            <tbody>                 
                                <tr v-for="movimiento in objetosPaginados" v-bind:key="movimiento">
                                    <td v-for="header in tableHeaders" :key="header">{{movimiento[header[0]]}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div v-else class="flex-grow-1 d-flex flex-column">
                    <h5 class="ms-2 mt-2">{{vista==0?'Gráfica desgaste':'Gráfica presión'}}</h5>
                    <div class="flex-grow-1 flex-column justify-content-center mt-2" ref="containerRef">
                        <DxChart ref="chartRef" id="desgaste" :data-source="desgaste" palette="Violet" v-if="vehiculoSeleccionado && vista === 0" @legend-click="legendClickHandler($event)">
                            <DxCommonSeriesSettings type="line" argument-field="fecha"/>
                            <DxSeries v-for="llanta in llantas" :key="llanta.value" :value-field="llanta.value" :name="llanta.nombre"/>
                            <DxSeries type="line" :value-field="desgasteLimit" name="desgaste" color="#ff7c7c"/>
                            <DxArgumentAxis :value-margins-enabled="false" discrete-axis-division-mode="crossLabels" argumentType="datetime">
                                <DxLabel :format=" 'shortDate'"/>
                                <DxGrid :visible="true"/>
                            </DxArgumentAxis>
                            <DxValueAxis :max-value-margin="0.01">
                              <DxConstantLine :width="2" :value="desgasteMax" color="#8c8cff" dash-style="dash">
                                <DxLabel text="Desgaste"/>
                              </DxConstantLine>
                            </DxValueAxis>
                            <DxLegend vertical-alignment="bottom" horizontal-alignment="center" item-text-position="bottom" />
                            <DxTooltip :enabled="true" :customizeTooltip="customizeTooltip"/>
                            <DxZoomAndPan :drag-to-zoom="true" :allow-mouse-wheel="true" value-axis="both" argument-axis="both" pan-key="shift"/>
                        </DxChart>

                        <DxChart id="presion" :data-source="presion" palette="Violet" v-if="vehiculoSeleccionado && vista === 1" @legend-click="legendClickHandler($event)">
                            <DxCommonSeriesSettings type="line" argument-field="fecha"/>
                            <DxSeries v-for="llanta in llantas" :key="llanta.value" :value-field="llanta.value" :name="llanta.nombre"/>
                            <DxArgumentAxis :value-margins-enabled="false" discrete-axis-division-mode="crossLabels" argumentType="datetime">
                                <DxLabel :format=" 'shortDate'"/>
                                <DxGrid :visible="true"/>
                            </DxArgumentAxis>
                            <DxValueAxis :max-value-margin="0.01">
                              <DxConstantLine :width="2" :value="presionBaja" color="#8c8cff" dash-style="dash">
                                <DxLabel text="Presión Baja"/>
                              </DxConstantLine>
                              <DxConstantLine :width="2" :value="presionAlta" color="#ff7c7c" dash-style="dash">
                                <DxLabel text="Presión Alta"/>
                              </DxConstantLine>
                            </DxValueAxis>
                            <DxLegend vertical-alignment="bottom" horizontal-alignment="center" item-text-position="bottom" />
                            <DxTooltip :enabled="true" :customizeTooltip="customizeTooltip"/>
                            <DxZoomAndPan :drag-to-zoom="true" :allow-mouse-wheel="true" value-axis="both" argument-axis="both" pan-key="shift"/>
                        </DxChart>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ListaVehiculos ref="listaVehiculosRef" @seleccionVehiculo="seleccionVehiculo"/>

    <div class="modal" id="modal-variables">
        <div class="modal-dialog modal-sm modal-dialog-centered">
            <div class="modal-content modal-sm">
                <div class="modal-header">
                    <h5 class="modal-title">Variables</h5>
                </div>

                <div class="modal-body">
                    <div class="row px-3">
                        <div>Desgaste Máximo</div>
                        <input id="marca-text"  type="number" min="0" maxlength="20" style="width: 100%" v-model="newDesgasteMax" class="form-control">
                    </div>
                    
                    <div class="row mt-3 px-3">
                        <div>Presión Mínima</div>
                        <input id="marca-text"  type="number" min="0" maxlength="20" style="width: 100%" v-model="newPresionBaja" class="form-control">
                    </div>

                    <div class="row mt-3 px-3">
                        <div>Presión Máxima</div>
                        <input id="marca-text"  type="number" min="0" maxlength="20" style="width: 100%" v-model="newPresionAlta" class="form-control">
                    </div>
                </div>

                <div class="modal-footer">
                    <button ref="closeModalRef" type="button" id="closeModal" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    <button id="saveButton" form="myForm" class="btn btn-success" :disabled="hasChanged" @click="guardarVariables()">Guardar cambios</button>
                </div>
            </div>
        </div>
    </div>

    <div ref="toastRef" id="toastAlert" class="toast bg-secondary text-white position-fixed bottom-0 end-0 m-3" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
            <div class="toast-body">
                {{alertMessage}}
            </div>
        <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
    </div>
    

</template>

<script>
import { Modal } from 'bootstrap'
import { useRoute } from 'vue-router';
import {computed, ref, onMounted} from 'vue'
import ListaVehiculos from '../components/lista-vehiculos.vue'
import LlantaVehiculoForm from '../components/llanta-vehiculo-form.vue'
import medicionesService from '../services/medicionesService.js'
import movimientosService from '../services/movimientosService.js'
import usePaginacion from '../composable/paginacion.js'
import variablesGlobalesService from '../services/variablesGlobalesService.js'
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxExport,
  DxGrid,
  DxMargin,
  DxLegend,
  DxTitle,
  DxSubtitle,
  DxTooltip,
  DxConstantLine,
  DxLabel,
  DxVisualRange,
  DxValueAxis,
  DxZoomAndPan,
  DxSize,
  DxAggregationInterval,
  DxAdaptiveLayout,
} from 'devextreme-vue/chart';
import {formatMeasures, linearRegresion} from '../composable/otros.js'

export default {
    name:'Reportes',
    components:{ListaVehiculos, LlantaVehiculoForm,
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxExport,
    DxGrid,
    DxMargin,
    DxLegend,
    DxTitle,
    DxSubtitle,
    DxTooltip,
    DxConstantLine,
    DxLabel,
    DxVisualRange,
    DxValueAxis,
    DxZoomAndPan,
    DxSize,
    DxAggregationInterval,
    DxAdaptiveLayout,
    },
    setup(){    
        const vehiculoSeleccionado = ref( null);
        const vehiculos = ref([]);
        const vista = ref(0);
        const vistas = (['Desgaste', 'Presión', 'Movimientos', 'Variables']);
        const desgaste = ref([])
        const llantas = ref([]);
        const desgasteMax = ref();
        const presionAlta = ref();
        const presionBaja = ref();
        const newDesgasteMax = ref();
        const newPresionAlta = ref();
        const newPresionBaja = ref();
        const emptyRequireds = ref([]);
        const presion = ref([])
        const tableHeaders = ([
            ['movimiento','Movimiento'],
            ['vehiculo_origen','Vehiculo origen'],
            ['posicion_origen','Posición origen'],
            ['serie_llanta','Llanta'],
            ['vehiculo_destino','Vehiculo destino'],
            ['posicion_destino','Posición destino'],
            ['serie_llanta_cambio','Llanta cambio'],
            ['fecha_reg','Fecha'],
            ['folio','Folio'],
        ])
        
        const listaVehiculosRef = ref(null);
        const closeModalRef = ref(null);
        const toastRef = ref(null);
        const chartRef = ref(null);
        const containerRef = ref(null);
        const loading = ref(false);
        const desgasteLimit = ref();
        const prevLlanta = ref();
        const alertMessage = ref();
        const route = useRoute();
        const height = ref(0)

        const paginas = ref(17);
        const paginaActual = ref( 1);
        const paginasVista = 8
        const paginacion = ref(new usePaginacion([], paginas.value, paginasVista));
        const search = ref('');
        const searched = ref(false);
        const vistaActual = ref(1);
        
        const movimientos_service = ref( new movimientosService);
        const mediciones_service = ref( new medicionesService);
        const variables_service = ref( new variablesGlobalesService);

        const objetosPaginados = computed(() => paginacion.value.paginados(paginaActual.value));
        const totalPaginas = computed(() => paginacion.value.totalPaginas());
        const totalVistas = computed(() => paginacion.value.totalVistas(vistaActual.value));
        const hasChanged = computed(()=>{            
            if((newDesgasteMax.value != desgasteMax.value || newPresionAlta.value != presionAlta.value || newPresionBaja.value != presionBaja.value) 
                && (newDesgasteMax.value !== '' && newPresionAlta.value != '' && newPresionBaja.value != '')){
                return false;
            }
            return true;
        });

        function legendClickHandler(e){
            let currentLlanta = e.target.name.substring(1);
            if(e.target.name.includes('P') && e.element.id === 'desgaste'){
                if(prevLlanta.value != currentLlanta){
                    desgasteLimit.value = 'd'+ currentLlanta;
                    prevLlanta.value = currentLlanta;
                    setTimeout(async () => {
                        const series = chartRef.value.instance.getSeriesByName('desgaste');
                        const point = series.getPointByPos(1);
                    point.showTooltip();
                    }, 10);     
                }else{
                     desgasteLimit.value = null;
                     prevLlanta.value = null;
                }
            }
        }

        onMounted(async () => {
            //await movimientos_service.value.readTiposMovimiento().then(data=>tiposMovimiento.value = data);
            if(route.params.vehiculo){
                vehiculoSeleccionado.value = JSON.parse(route.params.vehiculo);
                loadData(vehiculoSeleccionado.value.id_vehiculo);
            }
        });

        const  openListaVehiculos =  ()=>{
            listaVehiculosRef.value.open();
        }
        function customizeTooltip(pointInfo) {
            var date = new Date(pointInfo.argumentText);
            var aaaa = date.getFullYear();
            var mm = String(date.getMonth() + 1).padStart(2,'0');
            var dd = String(date.getDate()).padStart(2,'0')
            return {
                text: `${dd + '/' + mm + '/' + aaaa }<br/>${Number(pointInfo.valueText).toFixed(2)} ${vista.value===0?'mm':'PSI'} `,
            };
        }

        async function guardarVariables(){
            try{
                loading.value = true;
                closeModalRef.value.click();
                if(newDesgasteMax.value != desgasteMax.value){
                    await variables_service.value.updateVariables(1, newDesgasteMax.value);
                }
                if(newPresionBaja.value != presionBaja.value){
                    await variables_service.value.updateVariables(2, newPresionBaja.value);
                }
                if(newPresionAlta.value != presionAlta.value){
                    await variables_service.value.updateVariables(3, newPresionAlta.value);
                }
                loadData(vehiculoSeleccionado.value.id_vehiculo);
                alertMessage.value = 'Actualizado con exito';
            }catch{
                alertMessage.value = e.response.data.error;
                toastRef.value.classList.remove("bg-secondary");
                toastRef.value.classList.add("bg-danger");
                loading.value = false;
            }
            showAlert();
        }

        async function loadData(idVehiculo){
            desgasteLimit.value = null;
            llantas.value = [];
            loading.value = true;
            for(let i=1; i<=vehiculoSeleccionado.value.numero_llantas; i++){
                llantas.value.push({'value': String(i), 'nombre': 'P'+String(i)});
            }
            await variables_service.value.readVariables().then(data=>{
                desgasteMax.value = newDesgasteMax.value = parseFloat(data[0].valor_variable);
                presionBaja.value = newPresionBaja.value = parseFloat(data[1].valor_variable);
                presionAlta.value = newPresionAlta.value = parseFloat(data[2].valor_variable);
            });
            await movimientos_service.value.readMovimientosId(idVehiculo).then(data=> paginacion.value.updateLista(data));
            await mediciones_service.value.readDesgaste(idVehiculo).then(data=> desgaste.value = data);
            await mediciones_service.value.readPresion(idVehiculo).then(data=> presion.value = data);
            loading.value=false;
        };

        function seleccionVehiculo(vehiculo){
            vehiculoSeleccionado.value = vehiculo;
            vista.value = 0;
            loadData(vehiculo.id_vehiculo);
            setTimeout(() => {
                height.value = containerRef.value.clientHeight;
                paginas.value = parseInt((height.value - 30) / 30.6);
                paginacion.value.updateNumPaginas(paginas.value);
                console.log(height.value, paginas.value);
            }, 20);
        };

        function changeVista(index){
            if(index === 3){
                newDesgasteMax.value = desgasteMax.value;
                newPresionBaja.value = presionBaja.value;
                newPresionAlta.value = presionAlta.value;
                let modal = new Modal(document.getElementById('modal-variables'),{});
                modal.show();
            }else{
                vista.value = index
            }
        }

        function showAlert(){
            if(loading.value){
                setTimeout(showAlert, 50);
                return;
            }
            toastRef.value.classList.add('show');
            setTimeout(() => {
                toastRef.value.classList.remove('show');
                if(toastRef.value.classList.contains("bg-danger")){
                  toastRef.value.classList.remove("bg-danger");
                  toastRef.value.classList.add("bg-secondary");
                }
            }, 3000);
        }

        async function searchVehiculo(search){
            loading.value = true;
            await vehiculos_service.value.searchVehiculo(search).then(data=>paginacion.value.updateLista(data));
            searched.value = true;
            loading.value = false;
        };

        async function cancel(){
            if(!search.value && searched.value){
                loading.value = true;
                await vehiculos_service.value.readVehiculos().then(data=>paginacion.value.updateLista(data));
                loading.value = false;
                searched.value = false;
            }
        };

        return{
            alertMessage,
            changeVista,
            chartRef,
            closeModalRef,
            containerRef,
            customizeTooltip,
            desgaste,
            desgasteLimit,
            desgasteMax,
            emptyRequireds,
            guardarVariables,
            hasChanged,
            height,
            legendClickHandler,
            listaVehiculosRef,
            llantas,
            loadData,
            loading,
            newDesgasteMax,
            newPresionAlta,
            newPresionBaja,
            objetosPaginados,
            openListaVehiculos,
            paginaActual,
            paginacion,
            paginasVista,
            presion,
            presionAlta,
            presionBaja,
            search,
            searched,
            seleccionVehiculo,
            showAlert,
            tableHeaders,
            toastRef,
            totalPaginas,
            totalVistas,
            vehiculos,
            vehiculoSeleccionado,
            vista,
            vistaActual,
            vistas,
        }
    }
}
</script>

<style>

    .table-hover tr:hover {
        background:#0033ff41;
        cursor: pointer;
    }
    .dropdown-menu li:hover {
        background:#0033ff41;
        cursor: pointer;
    }

    input[type="search"]::-webkit-search-cancel-button:hover {
        cursor: pointer;
    }

    #dropdownMenuButton1{
        min-width: 110px;
    }

    th:hover{
        cursor: pointer;
    }

    #desgaste,#presion{
        max-width: 100%;
        width: 100%;
        height: 100%;
    }
</style>