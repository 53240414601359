<template>
        <div v-show="loading" class="over-layed">
            <div class="spinner-border spinner-lg" role="status"></div>
        </div>
        <div >
            <div class="row">
                <h4>Inicio</h4>
            </div>
    
        <div class="row mt-2 container-fluid h-100 px-0 mx-0">
            <div class="col-8  d-flex flex-column">
                <div class="row">
                    <div class="col">
                        <div class="bg-white shadow p-2">
                            <h6 class="mb-0">Vehículos</h6>
                            <hr class="mt-0"> 
                            <div class="indicador mt-2"><i class="bi bi-truck"/>&nbsp;{{vehiculos.length}} Vehículos activos</div>
                        </div>  
                    </div>

                    <div class="col">
                        <div class="bg-white shadow p-2">
                            <h6 class="mb-0">Usuarios</h6>
                            <hr class="mt-0"> 
                            <div class="indicador mt-2"><i class="bi bi-person"/>&nbsp;{{usuarios.length}} Usuarios activos</div>
                        </div>  
                    </div>

                    <div class="col">
                        <div class="bg-white shadow p-2">
                            <h6 class="mb-0">Dispositivos</h6>
                            <hr class="mt-0">
                            <div class="indicador mt-2"><i class="bi bi-phone"/>&nbsp;{{dispositivos.length}} Dispositivos activos</div>
                        </div>  
                    </div>
                </div>

                <div class="row mt-4 flex-grow-1">
                    <div class="col-12">
                        <div class="bg-white shadow p-2 h-100" ><!--v-if="ultimaMedicion"-->
                            <h6 class="mb-0">Antigüedad de mediciones</h6>
                            <hr class="mt-0">
                            <div class="table-responsive mt-2">
                                <table class="table table-borderless table-sm " style="white-space: nowrap; font-size:.9rem;" >
                                    <thead>
                                        <th></th>
                                        <th v-for="header in antiguosHeader" :key="header">{{header[1]}}</th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(medicion, index) in medicionesAntiguas" v-bind:key="medicion">
                                            <td>{{index+1}}</td>
                                            <td v-for="header in antiguosHeader"  :key="header">{{medicion[header[0]]}}</td>
                                        </tr>
                                    </tbody>   
                                </table>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
    
            <div class="col-4">
                <div class="bg-white shadow p-2 h-100 d-flex flex-column">
                    <h6 class="mb-0">Tipos de vehículos</h6>
                    <hr class="mt-0">
                    <div class="row flex-grow-1 align-items-center">
                    <div class="d-flex justify-content-center">
                        <DxPieChart id="pie" :data-source="tiposData" palette="Bright" alignm>
                            <DxSeries argument-field="tipo" value-field="value">
                                <DxLabel :visible="true">
                                    <DxConnector :visible="true" :width="1"/>
                                </DxLabel>
                                <DxFont :size="100"/>
                            </DxSeries>
                            <DxSize :width="350"/>
                            <DxLegend
                            :column-count="3"
                            orientation="horizontal"
                            item-text-position="right"
                            horizontal-alignment="center"
                            vertical-alignment="bottom"
                            />
                        </DxPieChart>
                    </div>
                    </div>
                </div>
            </div>    
        </div>

        <div class="row mt-4 mx-0" v-if="ultimaMedicion">
            <div class="col">
                <div class="bg-white shadow p-2">
                    <h6 class="mb-0">Ultima medición realizada</h6>
                    <hr class="mt-0">
                    <div class="table-responsive mt-2">
                        <table class="table table-borderless table-sm" style="white-space: nowrap; font-size:.9rem;" >
                            <thead>
                                <th v-for="header in ultimaHeader" :key="header">{{header[1]}}</th>
                            </thead>
                        <tbody>
                            <td v-for="header in ultimaHeader"  :key="header">{{ultimaMedicion[header[0]]}}</td>
                        </tbody>   
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script ></script>

<script>
import vehiculosService from '../services/vehiculosService.js'
import medicionesService from '../services/medicionesService.js'
import usuariosService from '../services/usuariosService.js'
import dispositivosService from '../services/dispositivosService.js'
import tiposVehiculoService from '../services/tiposVehiculoService.js'

import DxPieChart, {
  DxSize,
  DxSeries,
  DxLabel,
  DxConnector,
  DxExport,
  DxLegend,
  DxFont
} from 'devextreme-vue/pie-chart';

export default {
    name: 'Inicio',
    components: {
    DxPieChart,
    DxSize,
    DxSeries,
    DxLabel,
    DxConnector,
    DxExport,
    DxLegend,
    DxFont
  },
data(){
    return{
        usuarios:[],
        vehiculos:[],
        nombres:[],
        medicionesAntiguas:[],
        dispositivos:[],
        ultimaMedicion:null,
        loading: false,
        tiposData:[],
        antiguosHeader: [
            ['tipo','Tipo'],
            ['marca','Marca'],
            ['modelo','Modelo'],
            ['no_economico','No. Económico'],
            ['placa_estatal','Placa Estatal'],
            ['placa_federal','Placa Federal'],
            ['fecha_reg','Fecha'],
        ],
        ultimaHeader: [
            ['tipo','Tipo'],
            ['marca','Marca'],
            ['modelo','Modelo'],
            ['no_economico','No. Económico'],
            ['placa_estatal','Placa Estatal'],
            ['placa_federal','Placa Federal'],
            ['posicion', 'Posición'],
            ['tipo_medicion', 'Tipo Medición'],
            ['valor', 'Valor'],
            ['usuario_reg','Usuario'],
            ['fecha_reg','Fecha'],
        ],
    }
},
methods:{
    dataTipos(){
        this.tiposData=[];
        this.nombres.forEach(tipo => {
            let count = this.vehiculos.filter(vehiculo=> vehiculo.tipo_vehiculo === tipo.nombre && vehiculo.activo).length;
            this.tiposData.push({tipo: tipo.nombre, value: count});
        });
    },
},
usuariosService: null,
dispositivosService:null,
vehiculosService: null,
medicionesService: null,
tiposVehiculoService: null,

created(){
    this.usuariosService = new usuariosService;
    this.dispositivosService = new dispositivosService;
    this.vehiculosService = new vehiculosService;
    this.medicionesService = new medicionesService;
    this.tiposVehiculoService = new tiposVehiculoService;
    
},
async mounted(){
    this.loading = true;
    await this.vehiculosService.readVehiculos().then(data=>this.vehiculos = data.filter(o=>o.activo));

    await this.usuariosService.readUsuarios().then(data=>this.usuarios = data.filter(o=>o.activo));
    await this.dispositivosService.readDispositivos().then(data=>this.dispositivos = data);
    
    //await this.tiposVehiculoService.readTipos().then(data=>this.tipos = data);
    await this.tiposVehiculoService.readNombres().then(data=>this.nombres = data);
    await this.medicionesService.readMedicionesAntiguas().then(data=>this.medicionesAntiguas = data);
    await this.medicionesService.readUltimaMedicion().then(data=> this.ultimaMedicion = data.length>0 ? data[0]:null);
    this.dataTipos();
    this.loading = false;
    
}
}


</script>

<style scoped>
    .indicador{
        font-size: 1.25em;
    }
</style>