<template>
    <div class="container-fluid h-100">
        <div class="d-flex flex-column h-100">
            <div class="row align-items-center justify-content-between px-0 g-0 mb-2 ">
                <div class="col-6">
                    <h4>Dispositivos</h4>
                </div>
                <div v-show="tableLoaded " class="col-auto">
                    <nav aria-label="..." v-show="totalPaginas>1">
                        <ul class="pagination pagination-sm justify-content-end mb-0">
                            <li v-bind:class="{'disabled':vista==1 , 'page-item': true} ">
                              <a class="page-link" href="javascript:;" @click="vista--"><i class="bi bi-chevron-double-left"></i></a>
                            </li>
                            <li v-for="pagina in paginasVista-Math.max((vista*paginasVista)-totalPaginas, 0)" :key="pagina.id" v-bind:class="{'active': pagina+(paginasVista*(vista-1))==paginaActual, 'page-item':true} "><a class="page-link" href="javascript:;" @click="paginaActual = pagina+(paginasVista*(vista-1));getDataPagina(pagina+(paginasVista*(vista-1)))">{{pagina+(paginasVista*(vista-1))}}</a></li>
                            
                            <li v-bind:class="{'disabled':vista >= (totalPaginas/paginasVista) , 'page-item': true}">
                              <a class="page-link" href="javascript:;" @click="vista++"><i class="bi bi-chevron-double-right"></i></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div class="row justify-content-between px-0 g-0 mb-2" v-show="tableLoaded">
                <div class="col-8">
                    <div class = "input-group input-group-sm mb-0 " >
                        <input v-model="search" v-show="tableLoaded" type="search" class="form-control form-control-sm" @input="cancel()"/>
                        <div class="input-group-append">
                            <button class="btn btn-secondary btn-sm" type="button" @click="searchDispositivo(search)"><i class="bi bi-search"></i></button>
                        </div>
                    </div>
                </div>

                <div class="col-2">
                    <div class="dropdown px-2">
                        <button class="btn btn-sm btn-light btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi bi-table"></i>                                
                        </button>
                            <ul class="dropdown-menu p-2" aria-labelledby="dropdownMenuButton1">
                              <li v-for="header in tableHeaders" :key=" header">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" v-model="header[2]">
                                    <label class="form-check-label" >
                                      {{header[1]}}
                                    </label>
                                </div>
                              </li>
                            </ul>
                    </div>
                </div>

                <div class="col-2 text-end">
                    <button class="btn btn-success btn-sm" data-bs-toggle="modal" data-bs-target="#openModal" data-keyboard="false" data-backdrop="static" @click="edit = false;initializeValues();emptyRequireds=[]"><i class="bi bi-plus-lg" ></i> Agregar Dispositivo</button>
                </div>
                    
            </div>
            <div v-show="loading" class="over-layed">
                    <div class="spinner-border spinner-lg" role="status"></div>
            </div>
            <div class="table-responsive flex-grow-1 mb-0" ref="containerRef">
                <table v-show="tableLoaded" class="table table-bordered table-hover table-sm text" style="white-space: nowrap; font-size:.9rem;">
                    <thead>
                        <th v-for="header in tableHeaders.filter(header => header[2] === true)" :key="header" @click="ordenar(header[0])">
                            {{header[1]}}
                            <span v-if="header[0] === currentColumn">
                                <i :class="[orden ==='asc' ? 'bi bi-chevron-up' : 'bi bi-chevron-down' ]"></i>
                            </span>
                        </th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </thead>
                    <tbody>
                        <tr v-for="(dispositivo, index) in dispositivosPaginados" v-bind:key="dispositivo">
                            <td v-for="header in tableHeaders.filter(header => header[2] === true)"  :key="header" v-bind:class="{'text-center':header[0]=='activo'}">
                                {{ header[0].includes('fecha')? formatDate(dispositivo[header[0]]):dispositivo[header[0]]}}
                            </td>
                            <td style="text-align: center"><a href="#!"  data-bs-toggle="modal" data-bs-target="#openModal" @click="edit = true;idDispositivo=dispositivo.id_dispositivo;editar(index);" ><i class="bi bi-pencil-fill"></i></a></td>
                            <td style="text-align: center"><a href="#!"  data-bs-toggle="modal" data-bs-target="#modalEliminar" @click="delId = dispositivo.id_dispositivo;"><i class="bi bi-trash3-fill"></i></a></td>
                        </tr>
                    </tbody>   
                </table>
            </div>
        </div>

        <div id="toastAlert" class="toast bg-secondary text-white position-fixed bottom-0 end-0 m-3" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    {{alertMessage}}
                </div>
            <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
        </div>
    </div>



    <div  class="modal" id="openModal">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content"> 
                <div class="modal-header">
                    <h5 class="modal-title">Formulario de dispositivo</h5>
                </div>

                <div class="modal-body">
                    <div class="row justify-content-center">
                        <div class="row">
                            <div class="col">
                                <div>Dirección MAC*</div>
                                <input type="text" maxlength="17" style="width: 100%" v-model="data.mac_address" v-bind:class="{'form-control':true, 'is-invalid':invalidMac}" required v-bind:readonly="edit">
                                <div class="invalid-feedback"> La direccion MAC es requerida </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <div>Nombre</div>
                                <input type="text"  maxlength="50" style="width: 100%" v-model="data.nombre" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="modal-footer">
                  <button type="button" id="closeModal" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                  <button id="saveButton" form="myForm" class="btn btn-primary" @click.stop="upload()" :disabled="hasChanged">Guardar {{edit ? 'cambios' : 'nuevo dispositivo'}}</button>
                </div>
            </div>
        </div>
    </div>

    <div  class="modal" id="modalEliminar">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content"> 
                <div class="modal-header">
                    <h5 class="modal-title">Eliminar medición</h5>
                </div>
                <div class="modal-body">
                    ¿Está seguro que desea eliminar el dispositivo?
                </div>

                <div class="modal-footer">
                  <button type="button" id="closeModalDel" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                  <button type="button" id="saveButton" class="btn btn-success" @click="deleteDispositivo(delId); delId = null">Eliminar</button>
                </div>
            </div>
        </div>
    </div>
    
            

</template>

<script>
import usuariosService from '../services/usuariosService.js'
import dispositivosService from '../services/dispositivosService.js'
export default {
    name:'Dispositivos',
    data() {
        return {
            dipositivos:[],
            dispositivosPaginados:[],
            usuarios:[],

            idDispositivo:null,
            invalidMac:false,

            tableHeaders:[
                ['mac_address','MAC Address ',true],
                ['nombre','Nombre',true],
                ['usuario','Usuario asignado',true],
                ['profundidad_maxima','Profundidad máxima',true],
                ['fecha_calibracion','Fecha de calibración',true],
            ],
            orden:'asc',
            currentColumn:null,
            search:'',
            searched:false,
            delId:null,

            alertMessage: '',

            loading:false,
            tableLoaded: false,
            elementosPagina: 17,
            paginaActual: 1,
            paginasVista:10,
            vista:1,
            height:0,
            
            edit: false,

            data:{
                mac_address: "",
                nombre: "",
            },

            oldData:{
                mac_address: "",
                nombre: "",
            },

            changed: false,
        }   
    },
    methods:{

        async searchDispositivo(search){
            this.loading = true;
            await this.dispositivosService.searchDispositivo(search).then(data=>this.dispositivos = data);
            //this.ordenar(this.currentColumn, 0);
            this.getDataPagina(this.paginaActual);
            this.searched = true;
            this.loading = false;
        },
        
        getDataPagina(noPagina){
            this.dispositivosPaginados = [];
            let ini = (noPagina*this.elementosPagina) - this.elementosPagina;
            let fin = (noPagina * this.elementosPagina);

            this.dispositivosPaginados = this.dipositivos.slice(ini, fin);
        },
        async loadData(){
            this.loading = true;
            await this.dispositivosService.readDispositivos().then(data=>this.dipositivos = data);
            this.ordenar(this.currentColumn, 0);
            this.getDataPagina(this.paginaActual);
            this.tableLoaded = true;
            this.loading = false;
        },
        async upload(){
            if (this.data.mac_address != ''){
                this.invalidMac = false;
                document.getElementById('closeModal').click();
                this.loading = true;
                try{
                    if(this.edit){
                        await this.dispositivosService.updateDispositivo(this.idDispositivo, this.data);
                    }else{
                        await this.dispositivosService.createDispositivo(this.data);
                    }
                    this.loadData();
                    this.alertMessage = this.edit? 'Actualizado con exito':'Creado con exito';

                }catch(e){
                    this.alertMessage = e.response.data.error;
                    var toast = document.querySelector("#toastAlert");
                    toast.classList.remove("bg-secondary");
                    toast.classList.add("bg-danger");
                }
                this.loading = false;
                this.showAlert();
            }else{
                this.invalidMac = true;
            }
        },
        async deleteDispositivo(id){
            document.getElementById('closeModalDel').click();
            this.loading = true;
            try{
                await this.dispositivosService.deleteDispositivo(id);
                await this.loadData();
                this.alertMessage = 'Eliminado con exito';
            }catch(e){
                this.alertMessage = e.response.data.error;
                var toast = document.querySelector("#toastAlert");
                toast.classList.remove("bg-secondary");
                toast.classList.add("bg-danger");
            }
           
            this.loading = false;
            this.showAlert();

            if(this.paginaActual>this.totalPaginas){
                this.paginaActual=this.totalPaginas;
                this.getDataPagina(this.paginaActual);
            }
        },

        async editar(index){
            var dispositivo = this.dipositivos[index+((this.paginaActual-1)*this.elementosPagina)];
            this.oldData.mac_address = this.data.mac_address = dispositivo.mac_address;
            this.oldData.nombre = this.data.nombre = dispositivo.nombre;
        },

        initializeValues(){
            this.oldData.mac_address = this.data.mac_address ="";
            this.oldData.nombre = this.data.nombre ="";
        },

        showAlert(){
            if(this.loading){
                setTimeout(this.showAlert, 50);
                return;
            }

            var toast = document.querySelector("#toastAlert");
            toast.classList.add('show');
            setTimeout(() => {
                toast.classList.remove('show');
                if(toast.classList.contains("bg-danger")){
                  toast.classList.remove("bg-danger");
                  toast.classList.add("bg-secondary");
                }
            }, 3000);

        },

        ordenar(elemento, auto){
            if (auto == null){
                if (this.currentColumn === elemento){
                    this.currentColumn = this.orden ==='desc'? null:elemento;
                    this.orden = this.orden === 'asc'?'desc':'asc'; 
                }else{
                    this.orden = 'asc';
                    this.currentColumn = elemento;
                }
            }
            this.dipositivos.sort(this.compare);
            this.getDataPagina(this.paginaActual);
            
        },
        compare(a,b){
                var value = this.currentColumn??'mac_address';
                const varA = typeof a[value] === "string"
                      ? a[value].toUpperCase() : a[value];
                const varB = typeof b[value] === "string"
                      ? b[value].toUpperCase() : b[value];
                let comparison = 0;
                if (varA > varB) comparison = 1;
                else if (varA < varB) comparison = -1;
                return this.orden === "desc" ? comparison * -1 : comparison;
        },
        formatDate(date){
            if(date){
            var date = new Date(date);
            var aaaa = date.getFullYear();
            var mm = String(date.getMonth() + 1).padStart(2,'0');
            var dd = String(date.getDate()).padStart(2,'0');
            var hh = String(date.getHours()).padStart(2,'0');
            var Mm = String(date.getMinutes()).padStart(2,'0');
            var ss = String(date.getSeconds()).padStart(2,'0');
            return (dd + '-' + mm + '-' + aaaa + ' ' + hh + ':'+ Mm);
            } 
            return '';
        },
        cancel(){
            if(!this.search && this.searched){
                this.loadData();
                this.searched = false;
            }
        }
    },
    computed:{
        totalPaginas(){
            return Math.ceil(this.dipositivos.length / this.elementosPagina)
        },
        hasChanged(){
            if(JSON.stringify(this.data)!== JSON.stringify(this.oldData) ){
                return false;
            }
            return true;
        },
    },

    usuariosService: null,
    dispositivosService:null,

    created(){
        this.usuariosService = new usuariosService;
        this.dispositivosService = new dispositivosService;
    },

    async mounted(){
        this.loading = true;
        await this.usuariosService.readUsuarios().then(data=>this.usuarios = data);
        setTimeout(() => {
            this.height = this.$refs.containerRef.clientHeight;
            this.elementosPagina = parseInt((this.height - 60)/31.1);
        }, 20);
        await this.loadData();
        this.loading = false;
    }
    

}

</script>

<style scoped>
    .form-check-input:hover{
        cursor: pointer;
    }
    .watch-button:hover{
        cursor: pointer;
    }
    th:hover{
    cursor: pointer;
    }
    input[type="search"]::-webkit-search-cancel-button:hover {
      cursor: pointer;
    }
</style>