<template>
    <div class="container-fluid h-100">
        <div class="d-flex flex-column h-100">
            <div class="row align-items-center justify-content-between px-0 g-0 mb-2">
                <div class="col-6">
                    <h4 class="mb-0">Vehículos</h4>
                </div>
                <div class="col-auto" v-show="tableLoaded">
                    <nav aria-label="..." v-show="totalPaginas>1" class="mb-0">
                            <ul class="pagination pagination-sm justify-content-end mb-0">
                                <li v-bind:class="{'disabled':vista==1 , 'page-item': true} ">
                                  <a class="page-link" href="javascript:;" @click="vista--"><i class="bi bi-chevron-double-left"></i></a>
                                </li>

                                <li v-for="pagina in paginasVista-Math.max((vista*paginasVista)-totalPaginas, 0)" :key="pagina.id" v-bind:class="{'active': pagina+(paginasVista*(vista-1))==paginaActual, 'page-item':true} "><a class="page-link" href="javascript:;" @click="paginaActual = pagina+(paginasVista*(vista-1));getDataPagina(pagina+(paginasVista*(vista-1)))">{{pagina+(paginasVista*(vista-1))}}</a></li>
                                
                                <li v-bind:class="{'disabled':vista >= (totalPaginas/paginasVista) , 'page-item': true}">
                                  <a class="page-link" href="javascript:;" @click="vista++"><i class="bi bi-chevron-double-right"></i></a>
                                </li>
                            </ul>
                    </nav>
                </div>
            </div>

            <div class="row justify-content-between g-0 px-0 mb-2" v-show="tableLoaded">
                <div class="col-8">
                    <div class = "input-group input-group-sm mb-0 " >
                        <input v-model="search"  placeholder="Buscar vehículo" type="search" class="form-control form-control-sm" @input="cancel()"/>
                        <div class="input-group-append">
                            <button class="btn btn-secondary btn-sm" type="button" @click="searchVehiculo(search)"><i class="bi bi-search"></i></button>
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <div class="dropdown px-2">
                        <button class="btn btn-sm btn-light btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi bi-table"></i>                                
                        </button>
                            <ul class="dropdown-menu p-2" aria-labelledby="dropdownMenuButton1">
                              <li v-for="header in tableHeaders" :key=" header">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" v-model="header[2]">
                                    <label class="form-check-label" >
                                      {{header[1]}}
                                    </label>
                                </div>
                              </li>
                            </ul>
                    </div>
                </div>
                <div class="text-end col-2">
                    <button class="btn btn-success btn-sm" data-bs-toggle="modal" data-bs-target="#openModal" data-keyboard="false" data-backdrop="static" @click="edit = false;initializeValues();emptyRequireds=[]"><i class="bi bi-plus-lg" ></i> Agregar Vehiculo</button>
                </div>
            </div>
            
            <div v-show="loading" class="over-layed">
                    <div class="spinner-border spinner-lg" role="status"></div>
            </div>

            <div class="table-responsive flex-grow-1 mb-0" ref="containerRef">
                <table v-show="tableLoaded" class="table table-bordered table-hover table-sm mb-0" style="white-space: nowrap;font-size:.9rem;" >
                    <thead>
                        <th v-for="header in tableHeaders.filter(header => header[2] === true)" :key="header" @click="ordenar(header[0])">
                            {{header[1]}}
                            <span v-if="header[0] === currentColumn">
                                <i :class="[orden ==='asc' ? 'bi bi-chevron-up' : 'bi bi-chevron-down' ]"></i>
                            </span>
                        </th>
                        <th scope="col" >Mediciones</th>
                        <th scope="col" >Movimientos</th>
                        <th scope="col" >Estadísticas</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </thead>
                    <tbody>
                        <tr v-for="(vehiculo, index) in vehiculosPaginados" v-bind:key="vehiculo" v-bind:class="{'table-secondary': vehiculo.fecha_reg !== vehiculo.fecha_mod}">
                            <td v-for="header in tableHeaders.filter(header => header[2] === true)"  :key="header" v-bind:class="{'text-center':header[0]=='activo'}" v-bind:title="header[0] === 'fecha_mod'? vehiculo.usuario_mod: header[0] === 'fecha_reg'? vehiculo.usuario_reg:''">
                                {{header[0]!= 'activo'? header[0].includes('fecha')? formatDate(vehiculo[header[0]]): vehiculo[header[0]] :''}} <i class="bi bi-check" v-show="vehiculo.activo" v-if="header[0] == 'activo'" ></i>
                            </td>
                            <td style="text-align: center">
                                 <router-link :to="{name:'Mediciones', params:{id_vehiculo: vehiculo.id_vehiculo}}"><i class="bi bi-speedometer2"></i></router-link>
                            </td>
                            <td style="text-align: center">
                                 <router-link :to="{name:'Movimientos', params:{vehiculo: JSON.stringify(vehiculo)}}"><i class="bi bi-arrow-left-right"></i></router-link>
                            </td>
                            <td style="text-align: center">
                                 <router-link :to="{name:'Estadisticas', params:{vehiculo: JSON.stringify(vehiculo)}}"><i class="bi bi-clipboard2-data"></i></router-link>
                            </td>
                            <td style="text-align: center"><a href="#!"  data-bs-toggle="modal" data-bs-target="#openModal" @click="edit = true;idVehiculo=vehiculo.id_vehiculo; elIndex = index ;editar(index);emptyRequireds=[]" ><i class="bi bi-pencil-fill"></i></a></td>
                            <td style="text-align: center"><a href="#!"  data-bs-toggle="modal" data-bs-target="#modalEliminar" @click="idVehiculo = vehiculo.id_vehiculo; elIndex = index"><i class="bi bi-trash3-fill"></i></a></td>
                        </tr>
                    </tbody>   
                </table>
            </div>
        </div>

        

        <div id="toastAlert" class="toast bg-secondary text-white position-fixed bottom-0 end-0 m-3" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    {{alertMessage}}
                    <div class="mt-2 pt-2 border-top" v-show="constraint">
                      <button type="button" class="btn btn-primary btn-sm" @click="desactivar()">Desactivar</button>
                    </div>
                </div>
                <button type="button" v-bind:class="{'btn-close btn-close-white me-2 m-auto':true, 'mt-2':constraint}" data-bs-dismiss="toast" aria-label="Close" @click="constraint = false"></button>
                
            
            </div>
        </div>
    </div>



    <div  class="modal" id="openModal">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content"> 
                <div class="modal-header">
                    <h5 class="modal-title">Formulario de vehículos</h5>
                </div>
                <div class="modal-body">
                        <div class="row g-0">
                            <div class="col-8">
                                <div v-bind:class="{'mt-3': tiposDisponibles.length>1, 'row':true}">
                                    <div class="col-6 m4">
                                        <label>Tipo*</label>
                                        <select v-bind:class="{'form-select': true, 'is-invalid':emptyRequireds.includes(0)}" v-model="nombre" @change="listaTipos(nombre);" required>
                                            <option selected disabled value="">Seleccione un tipo</option>
                                            <option v-for="nombre in nombres" v-bind:key="nombre" v-bind:value="nombre.id_nombre">{{nombre.nombre}}</option>
                                        </select>
                                        <div class="invalid-feedback">El tipo de vehículo es requerido</div>
                                    </div>
                                    <div class="col-6 m4">
                                        <label>Marca*</label>
                                        <div class = "input-group mb-0 " v-show="newMarca" style="flex-wrap: nowrap">
                                            <input id="marca-text"  type="text" maxlength="20" style="width: 100%" v-model="newMarcaVal" v-bind:class="{'form-control':true, 'is-invalid':emptyRequireds.includes(1)}" v-bind:required="newMarca">
                                            <div class="input-group-append">
                                                <button class="btn btn-secondary" type="button" @click="newMarca = false; newMarcaVal = null; data.id_marca = currentMarca"><i class="bi bi-x"></i></button>
                                            </div>
                                        </div>
                                        
                                        <select v-show="!newMarca" v-bind:class="{'form-select':true, 'is-invalid':emptyRequireds.includes(1)}" v-model="data.id_marca" v-bind:required="!newMarca" autofocus @change="enableMarcaText()">
                                            <option selected disabled value="">Seleccione una marca</option>
                                            <option v-for="marca in marcas" v-bind:key="marca" v-bind:value="marca.id_marca">{{marca.nombre}}</option>
                                            <option value="-1">Agregar marca +</option>

                                        </select>
                                        <div class="invalid-feedback">La marca es requerida</div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-6 m4">
                                        <div>VIN*</div>
                                        <input type="text" maxlength="17" style="width: 100%" v-model="data.vin" v-bind:class="{'form-control':true, 'is-invalid':emptyRequireds.includes(2)}" required>
                                        <div class="invalid-feedback">
                                            El VIN es requerido
                                        </div>
                                    </div>
                                    <div class="col-6 m4">
                                        <div>Placa estatal*</div>
                                        <input type="text" maxlength="10" style="width: 100%" v-model="data.placa_estatal" v-bind:class="{'form-control':true, 'is-invalid':emptyRequireds.includes(3)}" required>
                                        <div class="invalid-feedback">
                                            La placa estatal es requerida
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-6 m4">
                                        <div>Placa federal</div>
                                        <input type="text" maxlength="10" style="width: 100%" v-model="data.placa_federal" class="form-control" required>
                                    </div>
                                    <div class="col-6 m4">
                                        <div>Modelo*</div>
                                        <input type="text" maxlength="50" style="width: 100%" v-model="data.modelo" v-bind:class="{'form-control':true, 'is-invalid':emptyRequireds.includes(4)}" required>
                                        <div class="invalid-feedback">
                                            El modelo es requerido
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-6 m4">
                                        <div>No. Económico</div>
                                        <input type="text" maxlength="20" style="width: 100%" v-model="data.no_economico" v-bind:class="{'form-control':true, 'is-invalid':emptyRequireds.includes(5)}" required>
                                        <div class="invalid-feedback">
                                            El número económico es requerido
                                        </div>
                                    </div>
                                    <div class="col-3 m4">
                                        <div>No. Llantas</div>
                                        <input type="text" style="width: 100%" v-model="noLlantas" class="form-control " readonly>
                                    </div>
                                    <div class="col-3 m4">
                                        <div>No. Ejes</div>
                                        <input type="text" style="width: 100%" v-model="noEjes" class="form-control" readonly>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" v-model="data.activo">
                                            <label class="form-check-label" >Activo</label>
                                        </div>
                                    </div>
                                        
                                </div>
                            </div>
                            <div class="col-4" >
                                <div class="d-flex justify-content-end ">
                                    <nav aria-label="..." >
                                        <ul class="pagination mb-0" v-show="tiposDisponibles.length>1">
                                            <li v-for="(t, index) in tiposDisponibles" v-bind:key="t" v-bind:class="{'active': t===tipoVehiculo(data.id_tipo_vehiculo), 'page-item':true}">
                                                <a class="page-link" href="#" @click="data.id_tipo_vehiculo = t.id_tipo_vehiculo;actualizarTipo()">{{index+1}}</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <div v-bind:class="{'mt-4':tiposDisponibles.length<=1,'d-flex justify-content-center ms-4 border border-secondary':true}" >
                                    <img :src="layout" class="img-fluid p-4" v-show="layout">
                                    <div class="py-5 px-3 px-auto text-center" v-show="!layout">Seleccione un tipo de vehículo</div>
                                </div>
                            </div>
                        </div>
                </div>
                <div class="modal-footer">
                  <button type="button" id="closeModal" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                  <button id="saveButton" form="myForm" class="btn btn-primary" @click.stop="upload()" :disabled="hasChanged">Guardar {{edit ? 'cambios' : 'nuevo vehículo'}}</button>
                </div>
            </div>
        </div>
    </div>

    <div  class="modal" id="modalEliminar">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content"> 
                <div class="modal-header">
                    <h5 class="modal-title">Eliminar medición</h5>
                </div>
                <div class="modal-body">
                    ¿Está seguro que desea eliminar el vehículo?
                </div>

                <div class="modal-footer">
                  <button type="button" id="closeModalDel" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                  <button type="button" id="saveButton" class="btn btn-success" @click="deleteVehiculo(idVehiculo);">Eliminar</button>
                </div>
            </div>
        </div>
    </div>
    
            

</template>

<script>
import vehiculosService from '../services/vehiculosService.js'
import marcasService from '../services/marcasServices.js'
import tiposVehiculoService from '../services/tiposVehiculoService.js'
export default {
    name:'Vehiculos',
    data() {
        return {
            vehiculos:[],
            marcas:[],
            nombres:[],
            tipos: [],
            emptyRequireds:[],

            elIndex: null,

            tiposDisponibles:[],

            
            tableHeaders:[
                ['tipo_vehiculo','Tipo ',true],
                ['numero_llantas','No. Llantas',true],
                ['numero_ejes','No. Ejes',true],
                ['marca','Marca',true],
                ['vin','VIN',true],
                ['placa_estatal','Placa estatal',true],
                ['placa_federal','Placa federal',true],
                ['modelo','Modelo',true],
                ['no_economico','No. Económico',true],
                ['activo','Activo',true],
                ['fecha_reg','Fecha de registro',false],
                ['usuario_reg','Registró',false],
                ['fecha_mod','Fecha de modificación',false],
                ['usuario_mod','Modificó',false],
            ],
            orden:'asc',
            currentColumn:null,

            tipoSeleccionado:null,
            idVehiculo:null,

            alertMessage: '',

            loading:false,
            tableLoaded: false,
            elementosPagina: 17,
            paginaActual: 1,
            paginasVista:10,
            vista:1,
            vehiculosPaginados:[],
            edit: false,

            search:'',
            searched:false,
            newMarca:false,
            newMarcaVal:'',
            currentMarca:'',
            elIndex:null,
            height:0,

            nombre: '',
            layout: '',
            noLlantas: '',
            noEjes: '',

            data:{
                id_tipo_vehiculo: null,
                id_marca: '',
                vin: '',
                placa_federal: null,
                placa_estatal: '',
                modelo: '',
                no_economico: '',
                activo: true,
                id_usuario_mod: localStorage.getItem('user_id'),
            },

            oldData:{  
                id_tipo_vehiculo: null,
                id_marca: '',
                vin: '',
                placa_federal: null,
                placa_estatal: '',
                modelo: '',
                no_economico: '',
                activo: true,
                id_usuario_mod: localStorage.getItem('user_id'),
            },

            changed: false,
            constraint:false,
        }   
    },
    methods:{
        async searchVehiculo(search){
            this.loading = true;
            await this.vehiculosService.searchVehiculo(search).then(data=>this.vehiculos = data);
            this.getDataPagina(this.paginaActual);
            this.searched = true;
            this.loading = false;
        },
        
        getDataPagina(noPagina){
            let ini = (noPagina*this.elementosPagina) - this.elementosPagina;
            let fin = (noPagina * this.elementosPagina);
            this.vehiculosPaginados = this.vehiculos.slice(ini, fin);
        },
        async loadData(){
            this.loading = true;
            await this.vehiculosService.readVehiculos().then(data=>this.vehiculos = data);
            this.ordenar(this.currentColumn, 0);
            this.getDataPagina(this.paginaActual);
            this.tableLoaded = true;
            this.loading = false;
        },
        async upload(){
            this.checkRequiredValues();
            if(this.emptyRequireds.length === 0){
                document.getElementById('closeModal').click();
                this.loading = true;
                if(this.newMarca){
                    try{
                        await this.marcasService.storeMarca({'nombre': this.newMarcaVal}).then(data=>
                        {
                            this.data.id_marca = data.id_marca
                        });
                    }catch{
                    }
                }
                try{
                    if(this.edit){
                        await this.vehiculosService.updateVehiculo(this.idVehiculo, this.data);
                    }else{
                        await this.vehiculosService.createVehiculo(this.data);
                }
                this.loadData();                
                this.alertMessage = this.edit? 'Actualizado con exito':'Creado con exito';

                }catch(e){
                    this.alertMessage = e.response.data.error;
                    var toast = document.querySelector("#toastAlert");
                    toast.classList.remove("bg-secondary");
                    toast.classList.add("bg-danger");

                }

                this.loading = false;
                this.showAlert();
            }
        },
        async deleteVehiculo(id){
            document.getElementById('closeModalDel').click();
            this.loading = true;
            try{
                await this.vehiculosService.deleteVehiculo(id);
                await this.loadData();
                this.alertMessage = 'Eliminado con exito';
            }catch(e){
                this.alertMessage = e.response.data.error;
                if(this.alertMessage.includes('Cannot delete or update')){
                    this.alertMessage = 'El elemento que desea eliminar cuenta con información registrada, por lo que solo puede ser desactivado.'
                    if(this.vehiculos[this.elIndex].activo){
                        this.constraint = true;
                    }
                }else{
                    var toast = document.querySelector("#toastAlert");
                    toast.classList.remove("bg-secondary");
                    toast.classList.add("bg-danger");
                }
            }
            this.loading = false;
            this.showAlert();

            if(this.paginaActual>this.totalPaginas){
                this.paginaActual=this.totalPaginas;
                this.getDataPagina(this.paginaActual);
            }
        },

        async editar(index){
            if(this.data.hasOwnProperty('id_usuario_reg')){
                delete this.data['id_usuario_reg'];
                delete this.oldData['id_usuario_reg'];
            }
            this.newMarca = false;
            this.newMarcaVal='';
            var vehiculo = this.vehiculos[index+((this.paginaActual-1)*this.elementosPagina)];
            this.oldData.id_tipo_vehiculo = this.data.id_tipo_vehiculo = this.tipos.find(t=> t.layout === vehiculo.layout 
                && t.id_nombre === this.nombres.find(n=> n.nombre === vehiculo.tipo_vehiculo).id_nombre).id_tipo_vehiculo;
            this.tipoSeleccionado = this.tipoVehiculo(this.data.id_tipo_vehiculo);
            
            this.nombre =  this.nombres.find(n => n.nombre === vehiculo.tipo_vehiculo).id_nombre;
            this.currentMarca = this.oldData.id_marca = this.data.id_marca = this.marcas.find(m=> m.nombre ==vehiculo.marca).id_marca;
            this.oldData.vin = this.data.vin = vehiculo.vin;
            this.oldData.placa_federal = this.data.placa_federal = vehiculo.placa_federal;
            this.oldData.placa_estatal = this.data.placa_estatal = vehiculo.placa_estatal;
            this.oldData.modelo = this.data.modelo = vehiculo.modelo;
            this.oldData.no_economico = this.data.no_economico = vehiculo.no_economico;
            this.oldData.activo = this.data.activo = vehiculo.activo;
            this.layout = vehiculo.layout;
            this.listaTipos(this.nombre);
        },

        desactivar(){
            this.editar(this.elIndex);
            this.data.activo = false;
            this.edit = true;
            this.upload();
            this.constraint = false;
            var toast = document.querySelector("#toastAlert");
            toast.classList.remove('show');
        },

        initializeValues(){
            this.tiposDisponibles = [];
            this.tipoSeleccionado = null;
            this.newMarca = false;
            this.newMarcaVal='';
            this.oldData.id_tipo_vehiculo = this.data.id_tipo_vehiculo= null;
            this.nombre= '';
            this.oldData.id_marca = this.data.id_marca = this.currentMarca = '';
            this.oldData.vin = this.data.vin= '';
            this.oldData.placa_federal = this.data.placa_federal = null;
            this.oldData.placa_estatal = this.data.placa_estatal= '';
            this.oldData.modelo = this.data.modelo= '';
            this.oldData.no_economico = this.data.no_economico= '';
            this.oldData.activo = this.data.activo= true;
            this.layout = '';
            this.oldData.id_usuario_reg = this.data.id_usuario_mod;
            this.data.id_usuario_reg = this.data.id_usuario_mod;
        },

        tipoVehiculo(idTipoVehiculo){
            var tipo = this.tipos.find(t =>
            t.id_tipo_vehiculo === idTipoVehiculo);
            return tipo;
        },

        listaTipos(id_nombre){
            var list = []
            this.tipos.forEach(function(tipo){
                if(tipo.id_nombre == id_nombre){
                    list.push(tipo);
                }
            })
            this.tiposDisponibles = list;
            this.tipoSeleccionado = this.tiposDisponibles[0];

            if(this.data.id_tipo_vehiculo!=null){
                if(this.elIndex!= null && this.vehiculos[this.elIndex].tipo_vehiculo === this.nombres.find(n=> n.id_nombre === this.nombre).nombre ){
                    this.tipoSeleccionado = this.tipoVehiculo(this.data.id_tipo_vehiculo);
                }
            }
            this.data.id_tipo_vehiculo = this.tipoSeleccionado.id_tipo_vehiculo;
            this.actualizarTipo(); 

        },

        actualizarTipo(){
            this.tipoSeleccionado = this.tipoVehiculo(this.data.id_tipo_vehiculo);
            this.layout = this.tipoSeleccionado.layout;
            this.noLlantas = this.tipoSeleccionado.numero_llantas;
            this.noEjes = this.tipoSeleccionado.numero_ejes;
        },

        checkRequiredValues(){
                this.emptyRequireds = []; 
                if(this.data.id_tipo_vehiculo == null){
                    this.emptyRequireds.push(0);
                }
                if(this.data.id_marca== '' || (this.newMarca && !this.newMarcaVal) ){
                    this.emptyRequireds.push(1);
                }
                if(this.data.vin== ''){
                    this.emptyRequireds.push(2);
                }
                if(this.data.placa_estatal== ''){
                    this.emptyRequireds.push(3);
                }
                if(this.data.modelo== ''){
                    this.emptyRequireds.push(4);
                }
                if(this.data.no_economico== ''){
                    this.emptyRequireds.push(5);
                }
         },
        showAlert(){
            if(this.loading){
                setTimeout(this.showAlert, 50);
                return;
            }

            var toast = document.querySelector("#toastAlert");
            toast.classList.add('show');
            if(!this.constraint){
                setTimeout(() => {
                    toast.classList.remove('show');
                    if(toast.classList.contains("bg-danger")){
                      toast.classList.remove("bg-danger");
                      toast.classList.add("bg-secondary");
                    }
                }, 3000);
            }

        },

        ordenar(elemento, auto){
            if (auto == null){
                if (this.currentColumn === elemento){
                    this.currentColumn = this.orden ==='desc'? null:elemento;
                    this.orden = this.orden === 'asc'?'desc':'asc'; 
                }else{
                    this.orden = 'asc';
                    this.currentColumn = elemento;
                }
            }
            this.vehiculos.sort(this.compare);
            this.getDataPagina(this.paginaActual);
            
        },
        compare(a,b){
                var value = this.currentColumn??'fecha_reg';
                const varA = typeof a[value] === "string"
                      ? a[value].toUpperCase() : a[value];
                const varB = typeof b[value] === "string"
                      ? b[value].toUpperCase() : b[value];
                let comparison = 0;
                if (varA > varB) comparison = 1;
                else if (varA < varB) comparison = -1;
                return this.orden === "desc" ? comparison * -1 : comparison;
        },
        formatDate(date){
            if(date){
            var date = new Date(date);
            var aaaa = date.getFullYear();
            var mm = String(date.getMonth() + 1).padStart(2,'0');
            var dd = String(date.getDate()).padStart(2,'0');
            var hh = String(date.getHours()).padStart(2,'0');
            var Mm = String(date.getMinutes()).padStart(2,'0');
            var ss = String(date.getSeconds()).padStart(2,'0');
            return (dd + '-' + mm + '-' + aaaa + ' ' + hh + ':'+ Mm);
            } 
            return '';
        },
        cancel(){
            if(!this.search && this.searched){
                this.loadData();
                this.searched = false;
            }
        },
        enableMarcaText(){
            this.newMarca=this.data.id_marca==='-1'?true:false;
            setTimeout(() => {
                document.getElementById('marca-text').focus();
            }, 50);
            
        }
    },
    computed:{
        totalPaginas(){
            return Math.ceil(this.vehiculos.length / this.elementosPagina)
        },
        hasChanged(){
            if(JSON.stringify(this.data)!== JSON.stringify(this.oldData) ){
                return false;
            }
            return true;
        },
    },

    vehiculosService: null,
    marcasService: null,
    tiposVehiculoService: null,

    created(){
        this.vehiculosService = new vehiculosService;
        this.marcasService = new marcasService;
        this.tiposVehiculoService = new tiposVehiculoService;
    },

    async mounted(){
        this.loading = true;
        await this.marcasService.readMarcas().then(data=>this.marcas = data);
        await this.tiposVehiculoService.readTipos().then(data=>this.tipos = data);
        await this.tiposVehiculoService.readNombres().then(data=>this.nombres = data);
        setTimeout(() => {
            this.height = this.$refs.containerRef.clientHeight;
            this.elementosPagina = parseInt((this.height - 60)/31.1);
        }, 20);
        await this.loadData();
    }

}

</script>

<style scoped>
th:hover{
    cursor: pointer;
}
.form-check-input:hover{
    cursor: pointer;
}

input[type="search"]::-webkit-search-cancel-button:hover {
  cursor: pointer;
}

</style>

