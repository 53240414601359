<template>
  <div class="container py-5">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-4">
          <div class="card-body p-5 text-center bg-white shadow">

            <img class="mb-3" src="../assets/logo.png" alt="" width="72" height="72">

            <h3 class="mb-4">APIClix-Llantas </h3>

            <div class="form-outline mb-4">
              <label class="form-label">Correo electrónico</label>
              <input id="emailInput" type="email" class="form-control" v-model="email"/>
            </div>

            <div class="form-outline mb-4">
            <label class="form-label" >Contraseña</label>
              <input id="passwordInput" type="password" class="form-control" v-model="password" />
              
            </div>

            <button id="accesButton" class="btn btn-primary w-100" type="button" @click="login"><span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{{loading?'Cargando':'Ingresar'}}</button>

          </div>

      </div>
    </div>

    <div id="toastAlert" class="toast bg-secondary text-white position-fixed bottom-0 end-0 m-3" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="d-flex">
        <div class="toast-body">
          {{alertMessage}}
        </div>
        <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
    name: 'Login',
    data() {
      return {
        email: '',
        password:'',
        alertMessage:'',
        loading: false
      }
    },
    mounted(){
      var sidebar = document.querySelector("#sidebar");
      var container = document.querySelector("#my-container");
      sidebar.classList.add("hide-nav");
      container.classList.add("full-cont");
    },
    methods:{
        async login(){
          var payload = {
                email: this.email,
                password: this.password,
                /*grant_type:'password',
                client_id:2,
                client_secret:'ztCRTcz5CNX6Zf90K1CbH8GRGubC5h62cItQxS4E',
                scope:'*'*/
          };
          this.loading = true;
          document.getElementById("accesButton").disabled=true;
          document.getElementById("emailInput").disabled=true;
          document.getElementById("passwordInput").disabled=true;
          var response;

          try{
            await this.axios.post('/loginweb', payload).then(response => {
              this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.data.access_token;
              localStorage.setItem('token', response.data.data.access_token);
              try {
                this.axios.get('/token/whois').then(response => {
                  localStorage.setItem('user_id', response.data.data.id_usuario);
                  this.$store.commit('setAuthenticated', true);
                  this.$router.push('/');
                  sidebar.classList.remove("hide-nav");
                  container.classList.remove("full-cont");
                });
              }
              finally{}
              
            });
            

          }catch(e){
            this.alertMessage = e.response.data.error;
            document.getElementById("accesButton").disabled=false;
            document.getElementById("emailInput").disabled=false;
            document.getElementById("passwordInput").disabled=false;
            var toast = document.querySelector("#toastAlert");
            toast.classList.remove("bg-secondary");
            toast.classList.add("bg-danger");
            this.showAlert();
           
          }
          this.loading = false;
           
          var sidebar = document.querySelector("#sidebar");
          var container = document.querySelector("#my-container");
          container.classList.add("active-cont");
          sidebar.classList.add("active-nav");
          
        },

        showAlert(){
            if(this.loading){
                setTimeout(this.showAlert, 50);
                return;
            }

            var toast = document.querySelector("#toastAlert");
            toast.classList.add('show');
            setTimeout(() => {
                toast.classList.remove('show');
                if(toast.classList.contains("bg-danger")){
                  toast.classList.remove("bg-danger");
                  toast.classList.add("bg-secondary");
                }
            }, 3000);

        }
    }
}
</script>

<style scoped>
  .hide-nav{
        width: 0px;
        text-align: center;
    }
    .full-cont{
        margin-left:0px;
    }
</style>