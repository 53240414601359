import axios from 'axios'

export default class medicionesService{
    createMedicion(data){
        return axios.post('/medicion', data).then(response=> response.data);
    }
    readMedicionId(idVehiculo){
        return axios.get(`/listaMedicionCompleta/${idVehiculo}`).then(response=> response.data.data);
    }
    readMediciones(){
        return axios.get('/medicion').then(response=> response.data.data);
    }/*
    readUltimasMediciones(){
        return axios.get('/listaUltimaMedicionCompleta').then(response=> response.data.data);
    }*/

    readUltimaMedicion(){
        return axios.get('/ultimamedicion').then(response=> response.data.data);
    }

    readMedicionesAntiguas(){
        return axios.get('/medicionesantiguas').then(response=> response.data.data);
    }

    readTiposMedicion(){
        return axios.get('/tipomedicion').then(response=> response.data.data);
    }
    readDesgaste(id){
        return axios.get(`/desgaste/${id}`).then(response=> response.data.data);
    }
    readPresion(id){
        return axios.get(`/presion/${id}`).then(response=> response.data.data);
    }
    updateMedicion(idMedicion, data){
        return axios.put(`/medicion/${idMedicion}`, data).then(response=> response.data);
    }
    deleteMedicion(idMedicion){
        return axios.delete(`/medicion/${idMedicion}`).then(response=> [response.status, response.statusText]);
    }
    
}