import { createWebHashHistory, createRouter } from "vue-router";
import Alertas from '../views/Alertas.vue'
import Dispositivos from '../views/Dispositivos.vue'
import Estadisticas from '../views/Estadisticas.vue'
import HistorialMovimientos from '../views/HistorialMovimientos.vue'
import Inicio from '../views/Inicio.vue'
import Login from '../views/Login.vue'
import Logs from '../views/Logs.vue'
import Mediciones from '../views/Mediciones.vue'
import Movimientos from '../views/Movimientos-ca.vue'
import Usuarios from '../views/Usuarios.vue'
import Vehiculos from '../views/Vehiculos.vue'


const routes = [
    {
        path:'/Alertas',
        name:'Alertas',
        component: Alertas
    },
    {
        path:'/Dispositivos',
        name:'Dispositivos',
        component: Dispositivos
    },
    {
        path:'/Estadisticas',
        name:'Estadisticas',
        component: Estadisticas
    },
    {
        path: '/HistorialMovimientos',
        name: 'HistorialMovimientos',
        component: HistorialMovimientos
    },
    {
        path:'/',
        name:'Inicio',
        component: Inicio
    },
    {
        path:'/Login',
        name:'Login',
        component: Login
    },
    {
        path:'/Logs',
        name:'Logs',
        component: Logs
    },
    {
        path:'/Mediciones',
        name:'Mediciones',
        component: Mediciones
    },
    {
        path:'/Movimientos',
        name:'Movimientos',
        component: Movimientos
    },
    {
        path:'/Usuarios',
        name:'Usuarios',
        component: Usuarios
    },
    {
        path:'/Vehiculos',
        name:'Vehiculos',
        component: Vehiculos
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

export default router;