<template>
    <div class="container-fluid h-100">
        <div class="h-100 d-flex flex-column">
            <div class="row align-items-center justify-content-between px-0 g-0 mb-3">
                <div class="col-6">
                    <h4 class="mb-0">Historial de movimientos</h4>
                </div>
                <div class="col-auto" v-show="tableLoaded">
                    <nav aria-label="..." v-show="totalPaginas>1" class="mb-0">
                        <ul class="pagination pagination-sm justify-content-end mb-0">
                            <li v-bind:class="{'disabled':vistaActual==1 , 'page-item': true} ">
                              <a class="page-link" href="javascript:;" @click="vistaActual--"><i class="bi bi-chevron-double-left"></i></a>
                            </li>

                            <li v-for="pagina in totalVistas" :key="pagina.id" v-bind:class="{'active': pagina+(paginasVista*(vistaActual-1))==paginaActual, 'page-item':true} "><a class="page-link" href="javascript:;" @click="paginaActual = pagina+(paginasVista*(vistaActual-1))">{{pagina+(paginasVista*(vistaActual-1))}}</a></li>
                            
                            <li v-bind:class="{'disabled':vistaActual >= (totalPaginas/paginasVista) , 'page-item': true}">
                              <a class="page-link" href="javascript:;" @click="vistaActual++"><i class="bi bi-chevron-double-right"></i></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <!--<div class="row justify-content-between g-0 px-0 mb-2" v-show="tableLoaded">
                <div class="col-8">
                    <div class = "input-group input-group-sm mb-0 " >
                        <input v-model="search"  placeholder="Buscar vehículo" type="search" class="form-control form-control-sm" @input="cancel()"/>
                        <div class="input-group-append">
                            <button class="btn btn-secondary btn-sm" type="button" @click="searchVehiculo(search)"><i class="bi bi-search"></i></button>
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <div class="dropdown px-2">
                        <button class="btn btn-sm btn-light btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi bi-table"></i>                                
                        </button>
                            <ul class="dropdown-menu p-2" aria-labelledby="dropdownMenuButton1">
                              <li v-for="header in tableHeaders" :key=" header">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" v-model="header[2]">
                                    <label class="form-check-label" >
                                      {{header[1]}}
                                    </label>
                                </div>
                              </li>
                            </ul>
                    </div>
                </div>
            </div>-->
            <div v-show="loading" class="over-layed">
                    <div class="spinner-border spinner-lg" role="status"></div>
            </div>

            <div class="table-responsive flex-grow-1 mb-0" ref="containerRef">
                <table v-show="tableLoaded" class="table table-bordered table-hover table-sm mb-0" style="white-space: nowrap; font-size:.9rem;" >
                    <thead>
                        <th v-for="header in tableHeaders.filter(header => header[2] === true)" :key="header" @click="ordenar(header[0])">
                            {{header[1]}}
                            <span v-if="header[0] === currentColumn">
                                <i :class="[orden ==='asc' ? 'bi bi-chevron-up' : 'bi bi-chevron-down' ]"></i>
                            </span>
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="movimiento in objetosPaginados" v-bind:key="movimiento">
                            <td v-for="header in tableHeaders.filter(header => header[2] === true)"  :key="header">
                                {{movimiento[header[0]]}} 
                            </td>
                        </tr>
                    </tbody>   
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {ref ,onMounted, computed} from 'vue';
import usePaginacion from '../composable/paginacion.js'
import movimientosService from '../services/movimientosService.js'

export default {
    name:'HistorialMovimientos',
    setup() {
        const loading = ref(false);
        const tableLoaded = ref(false);
        const paginaActual = ref( 1);
        const movimientos = ref([]);
        const paginasVista = 10
        const vistaActual = ref(1);
        const paginacion = ref(new usePaginacion([], 19, paginasVista));
        const currentColumn = ref();
        const orden = ref('asc');
        const search = ref('');
        const searched = ref(false);
        const containerRef = ref(null);
        const tableHeaders = [
                ['movimiento','Movimiento', true],
                ['vehiculo_origen','Vehículo origen', true],
                ['posicion_origen','Posición origen', true],
                ['serie_llanta','Llanta', true],
                ['vehiculo_destino','Vehículo destino', true],
                ['posicion_destino','Posición destino', true],
                ['serie_llanta_cambio','Llanta cambio', true],
                ['fecha_reg','Fecha', true],
                ['folio','Folio', true],
            ];

        const movimientos_service =ref( new movimientosService);

        onMounted(async()=>{
            setTimeout(() => {
                const height = containerRef.value.clientHeight;
                const ePaginas = parseInt((height - 30) / 30.6);
                paginacion.value.updateNumPaginas(ePaginas);
                console.log(height, ePaginas);
                }, 20);
            await movimientos_service.value.readMovimientos().then(data => {
            paginacion.value.updateLista(data); 
            tableLoaded.value = true; movimientos.value = data
        });});

        const objetosPaginados = computed(() => paginacion.value.paginados(paginaActual.value));
        const totalPaginas = computed(() => paginacion.value.totalPaginas());
        const totalVistas = computed(() => paginacion.value.totalVistas(vistaActual.value));

        /*async function searchMovimiento(search){
            loading.value = true;
            await llantas_service.value.searchLlanta(search).then(data=>paginacion.value.updateLista(data));
            searched.value = true;
            loading.value = false;
        };

        async function  loadLlantas(){
            loading.value = true;
            await llantas_service.value.readLlantasDisponibles().then(data=>paginacion.value.updateLista(data));
            loading.value = false;
        };

        async function cancel(){
            if(!search.value && searched.value){
                    loading.value = true;
                    await llantas_service.value.readLlantasDisponibles().then(data=>paginacion.value.updateLista(data));
                    searched.value = false;
            }
        };*/
        function ordenar(elemento, auto){
            if (auto == null){
                if (currentColumn.value === elemento){
                    currentColumn.value = orden.value ==='desc'? null:elemento;
                    orden.value = orden.value === 'asc'?'desc':'asc'; 
                }else{
                    orden.value = 'asc';
                    currentColumn.value = elemento;
                }
            }
            movimientos.value.sort(compare);
            paginacion.value.updateLista(movimientos.value)            
        }
        function compare(a,b){
                let value = currentColumn.value ??'folio';
                let varA = typeof a[value] === "string"
                      ? a[value].toUpperCase() : a[value];
                let varB = typeof b[value] === "string"
                      ? b[value].toUpperCase() : b[value];
                let comparison = 0;
                varA = varA===null? '': varA;
                varB = varB===null? '': varB;
                console.log(varA, varB, typeof varA, typeof varB);
                if (varA > varB) comparison = 1;
                else if (varA < varB) comparison = -1;
                return orden.value  === "desc" ? comparison * -1 : comparison;
        }
        


        return{
            currentColumn,
            loading, 
            objetosPaginados,
            orden,
            ordenar,
            paginaActual,
            paginasVista,
            tableHeaders,
            tableLoaded,
            totalPaginas,
            totalVistas,
            vistaActual,
            containerRef,
        }
    }
    
}
</script>

<style scoped>
    th:hover{
        cursor: pointer;
    }
</style>