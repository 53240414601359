<template>
    <div class="container-fluid h-100">
        <div class="h-100 d-flex flex-column">
            <div class="row align-items-center justify-content-between px-0 g-0 mb-2">
                <div class="col-auto">
                    <h4>Alertas</h4>
                </div>
            </div>

            <div v-show="loading" class="over-layed">
                <div class="spinner-border spinner-lg" role="status"></div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="bg-white shadow p-2">
                        <h6 class="mb-0">Llantas</h6>
                        <hr class="mt-0"> 
                        <div class="table-responsive mt-2">
                            <table class="table table-borderless table-sm " style="white-space: nowrap; font-size:.9rem;" >
                                <thead>
                                    <th></th>
                                    <th>Vehículo</th>
                                    <th v-for="header in llantasHeader" :key="header">{{header[1]}}</th>
                                    <th>Vida útil</th>
                                </thead>
                                <tbody>
                                    <tr v-for="(llanta, index) in llantas" v-bind:key="llanta">
                                        <td>{{index+1}}</td>
                                        <td>{{llanta.tipo_vehiculo + ' ' + llanta.marca_vehiculo}}</td>
                                        <td v-for="header in llantasHeader"  :key="header">{{llanta[header[0]]}}</td>
                                        <td :class="colorVidaUtil(llanta.vida_util)">{{vidaUtil(llanta.vida_util)}}</td>
                                    </tr>
                                </tbody>   
                            </table>
                        </div>
                    </div>  
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {computed, ref, onMounted} from 'vue'
import llantasVehiculoService from '@/services/llantasVehiculoService';

export default {
    name: 'Alertas',
    setup() {
        const loading = ref(false);
        const llantas = ref([]);
        const llantasHeader = [
            ['no_economico','No. Económico'],
            ['placa_estatal','Placa Estatal'],
            ['placa_federal','Placa Federal'],
            ['posicion','Posición'],
            ['serie','Serie'],
            //['desgaste','Desgaste'],
        ];

        const llantas_vehiculo_service =ref( new llantasVehiculoService);

        onMounted(async () => {
            loading.value = true;
            await llantas_vehiculo_service.value.readLlantasVehiculo().then(data=>{llantas.value = data.filter(reg=> reg.vida_util !== null); console.log(data.filter(reg=> reg.vida_util !== null))});
            loading.value = false;
        });

        function colorVidaUtil(vidaUtil){
            if(vidaUtil<150){
                return 'text-danger';
            }else if(vidaUtil<300){
                return 'text-warning';
            }   
            return '';
        }

        function vidaUtil(vidaUtil){
            if (vidaUtil){
                return vidaUtil + ' días';
            }
            return '';
        }

        return{
            loading,
            llantas,
            llantasHeader,
            colorVidaUtil,
            vidaUtil
        }
    }
}
</script>

<style scoped>
    .indicador{
        font-size: 1.25em;
    }
</style>