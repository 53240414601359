<template>
    <div  class="modal" id="llantas-modal">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Llantas</h5>
                </div>
                <div class="modal-body">
                    <div v-if="loading" class="over-layed text-center">
                        <div class="spinner-border spinner-lg" role="status"></div>
                    </div>
                    <div class="row g-0 mb-2 justify-content-between">
                        <div class="col-4 align-self-center">
                           <img :src="vehiculo? vehiculo.layout:''" class="img-fluid p-4">
                        </div>
                        <div class="col-8">
                            <div class="row">
                                <div class="col-auto">
                                    <label>Posición</label>
                                    <select class="form-select form-select-sm" v-model="dataMovimiento.posicion_destino">
                                        <option v-for="pos in posicionesLibres" :key="pos" :value="pos">{{pos}}</option>
                                        <option value="0">Refacción</option>
                                    </select>
                                </div>
                                <div class="col-auto align-self-end">
                                    <button class="btn btn-sm btn-success" @click="nuevaLlanta=!nuevaLlanta; if(!nuevaLlanta)loadLlantas()">{{nuevaLlanta? 'Llanta existente' : 'Llanta nueva'}}</button>
                                </div>
                            </div>
                            
                            <div class="p-0" v-if="nuevaLlanta">
                                <div class="row mt-3">
                                    <div class="col-6">
                                        <div>Serie*</div>
                                        <input type="text" maxlength="20" style="width: 100%" v-model="dataLlanta.serie" v-bind:class="{'form-control':true, 'is-invalid':emptyRequireds.includes(0)}" required>
                                        <div class="invalid-feedback">
                                            La serie es requerida
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div>Marca*</div>
                                            <div class = "input-group mb-0 " v-show="newMarca" style="flex-wrap: nowrap">
                                                <input id="marca-text"  type="text" maxlength="20" style="width: 100%" v-model="newMarcaVal" v-bind:class="{'form-control':true, 'is-invalid':emptyRequireds.includes(1)}" v-bind:required="newMarca">
                                                <div class="input-group-append">
                                                    <button class="btn btn-secondary" type="button" @click="newMarca = false; newMarcaVal = null; dataLlanta.id_marca_llanta = currentMarca"><i class="bi bi-x"></i></button>
                                                </div>
                                            </div>
                                            <select v-show="!newMarca" v-bind:class="{'form-select':true, 'is-invalid':emptyRequireds.includes(1)}" v-model="dataLlanta.id_marca_llanta" v-bind:required="!newMarca" @change="enableMarcaText()">
                                                <option selected disabled value="">Seleccione una marca</option>
                                                <option v-for="marca in marcasLlanta" v-bind:key="marca" v-bind:value="marca.id_marca_llanta">{{marca.nombre}}</option>
                                                <option value="-1">Agregar marca +</option>
                                            </select>
                                            <div class="invalid-feedback">La marca es requerida</div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-6">
                                        <div>Modelo</div>
                                        <input type="text" maxlength="20" style="width: 100%" v-model="dataLlanta.modelo" class="form-control">
                                    </div>
                                    <div class="col-6">
                                        <div>Rin</div>
                                        <input type="text" maxlength="20" style="width: 100%" v-model="dataLlanta.rin" class="form-control">
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-6">
                                        <div>Dimensión</div>
                                        <input type="text" maxlength="20" style="width: 100%" v-model="dataLlanta.dimension" class="form-control">
                                    </div>
                                </div>
                            </div>
                            
                            <div v-else>
                                <div class="row mt-3 g-0 mb-1 justify-content-between">
                                    <div class="col-7">
                                        <div class = "input-group input-group-sm mb-0 " >
                                            <input v-model="search"  placeholder="Buscar llanta" type="search" class="form-control form-control-sm" @input="cancel()"/>
                                            <div class="input-group-append">
                                                <button class="btn btn-secondary btn-sm" type="button" @click="searchLlanta(search)"><i class="bi bi-search"></i></button>
                                            </div>  
                                        </div>  
                                    </div>
                                    <div class="col-auto" v-show="totalPaginas>1"> 
                                        <nav aria-label="..." > 
                                            <ul class="pagination pagination-sm justify-content-end mb-0">  
                                                <li v-bind:class="{'disabled':vistaActual==1 , 'page-item': true} "> 
                                                  <a class="page-link" href="javascript:;" @click="vistaActual--"><i class="bi bi-chevron-double-left"></i></a> 
                                                </li>   
                                                <li v-for="pagina in totalVistas" :key="pagina.id" v-bind:class="{'active': pagina+(paginasVista*(vistaActual-1))==paginaActual, 'page-item':true} "><a class="page-link" href="javascript:;"   @click="paginaActual = pagina+(paginasVista*(vistaActual-1))">{{pagina+(paginasVista*(vistaActual-1))}}</a></li>
                                                <li v-bind:class="{'disabled':vistaActual >= (totalPaginas/paginasVista) , 'page-item': true}">
                                                  <a class="page-link" href="javascript:;" @click="vistaActual++"><i class="bi bi-chevron-double-right"></i></a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>

                                <div class="row table-responsive g-0">
                                    <table class="table table-sm table-striped table-bordered table-hover mb-0" style="white-space: nowrap;">
                                        <thead> 
                                            <th scope="col">Serie</th>   
                                            <th scope="col">Marca</th>  
                                            <th scope="col">Modelo</th> 
                                            <th scope="col">Rin</th>  
                                            <th scope="col">Dimensión</th>
                                        </thead>    
                                        <tbody>                 
                                            <tr v-for="llanta in objetosPaginados" v-bind:key="llanta" @click="seleccionLlanta(llanta.id_llanta)" :class="{'bg-primary':llantaSeleccionada === llanta.id_llanta}">
                                                <td>{{llanta.serie}}</td>
                                                <td>{{llanta.marca}}</td>
                                                <td>{{llanta.modelo}}</td>
                                                <td>{{llanta.rin}}</td>
                                                <td>{{llanta.dimension}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="invalid-feedback d-block mt-4 text-center">{{error}}</div>                               
                        </div>
                    </div>
                    
                </div>
                <div class="modal-footer">
                    
                    <button type="button" id="closeModal" class="btn btn-secondary" data-bs-dismiss="modal" @click="close();">Cerrar</button>
                    <button class="btn btn-success" @click="savePosicion();" :disabled='savePosicionButton' >Guardar posición</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ref ,onMounted, computed} from 'vue';
import { Modal } from 'bootstrap'
import llantasService from '../services/llantasService.js'
import marcasLlantaService from '../services/marcasLlantaService.js'
import movimientosService from '../services/movimientosService'
import usePaginacion from '../composable/paginacion.js'
import usetableFunctions from '../composable/tableFunctions'

export default {
    name:'LlantaVehiculoForm',
    props:{vehiculo:Object, posicionesOcupadas:Array,
    dataMovimiento:{
        default:{
                'id_tipo_movimiento': null,
                'id_llanta': null,
                'id_llanta_cambio': null,
                'id_usuario': localStorage.getItem('user_id'),
                'id_vehiculo_origen': null,
                'id_vehiculo_destino':null,
                'posicion_origen':null,
                'posicion_destino':null,
                'descripcion':null
        },
        type:Object}},
    setup(props,context) {

        const currentMarca = ref('');
        const modificado = ref(false)
        const error = ref('');
        const emptyRequireds = ref([]);
        const llantaSeleccionada = ref('');
        const loading = ref( false);
        const marcasLlanta = ref([]);
        const newMarca = ref(false);
        const newMarcaVal = ref('');
        const nuevaLlanta = ref(true);
        const numeroLlantas = ref(null);
        const paginaActual = ref( 1);
        const paginasVista = 5
        const posicionesLibres = ref([]);
        const search = ref('');
        const searched = ref(false);
        const vistaActual = ref(1);
        const dataLlanta = ref({
                'serie': null,
                'id_marca_llanta': '',
                'modelo': null,
                'rin': null,
                'dimension': null,
                'activo':true
            });
        const emptyDataLlanta = ref({
                'serie': null,
                'id_marca_llanta': '',
                'modelo': null,
                'rin': null,
                'dimension': null,
                'activo':true
        });

        const paginacion = ref(new usePaginacion([], 10, paginasVista));
        const marcasLlanta_service =ref( new marcasLlantaService);
        const llantas_service =ref( new llantasService);
        const movimientos_service =ref( new movimientosService);

        onMounted(async()=>{await marcasLlanta_service.value.readMarcas().then(data => marcasLlanta.value = data);});        
        
        const dataMovimiento = computed(()=>props.dataMovimiento);
        const posicionesOcupadas = computed(() => props.posicionesOcupadas); 
        const vehiculo = computed(() => props.vehiculo);
        const objetosPaginados = computed(() => paginacion.value.paginados(paginaActual.value));
        const totalPaginas = computed(() => paginacion.value.totalPaginas());
        const totalVistas = computed(() => paginacion.value.totalVistas(vistaActual.value));
        const vehiculosPaginados = computed(()=>paginacion.value.paginados(paginaActual.value));
        const savePosicionButton = computed(() => {
            if(nuevaLlanta.value && (JSON.stringify(dataLlanta.value)!== JSON.stringify(emptyDataLlanta.value))){
                return false;
            }else if(!nuevaLlanta.value && llantaSeleccionada.value){
                return false;
            }
            return true;

        });

        async function searchLlanta(search){
            loading.value = true;
            await llantas_service.value.searchLlanta(search).then(data=>paginacion.value.updateLista(data));
            searched.value = true;
            loading.value = false;
        };

        async function  loadLlantas(){
            loading.value = true;
            await llantas_service.value.readLlantasDisponibles().then(data=>paginacion.value.updateLista(data));
            loading.value = false;
        };

        async function cancel(){
            if(!search.value && searched.value){
                    loading.value = true;
                    await llantas_service.value.readLlantasDisponibles().then(data=>paginacion.value.updateLista(data));
                    searched.value = false;
            }
        };

        function getPosicionesLibres(){
            posicionesLibres.value = [];
            for(let i = 1;i<=vehiculo.value.numero_llantas;i++){
                posicionesLibres.value.push(i);
            }
            posicionesLibres.value = posicionesLibres.value.filter( p => !posicionesOcupadas.value.includes(p));
            dataMovimiento.value.posicion_destino = 0;
            if(posicionesLibres.value.length > 0){
                dataMovimiento.value.posicion_destino = posicionesLibres.value[0]
            }
        }

        function seleccionLlanta(id){
            if(llantaSeleccionada.value === id){
                llantaSeleccionada.value = '';
            }else{
                llantaSeleccionada.value = id;
                dataMovimiento.value.id_llanta = id;
            }
            
        };

        async function savePosicion(){
            error.value = '';
            loading.value = true;
            dataMovimiento.value.posicion_destino = dataMovimiento.value.posicion_destino == 0 ? null : dataMovimiento.value.posicion_destino;
            if(nuevaLlanta.value){
                checkRequiredValues();
                if(emptyRequireds.value.length === 0){
                    if(newMarca.value){
                        try{
                            await marcasLlanta_service.value.storeMarca({'nombre': newMarcaVal.value}).then(data=>{
                                dataMovimiento.value.id_marca_llanta = data.id_marca_llanta
                                dataLlanta.value.id_marca_llanta = data.id_marca_llanta
                            });
                            await marcasLlanta_service.value.readMarcas().then(data => marcasLlanta.value = data);
                        }catch(e){
                            error.value= e.response.data.error;
                        }
                    }
                    try{
                        console.log(dataLlanta.value)
                        await llantas_service.value.storeLlanta(dataLlanta.value).then(data=>dataMovimiento.value.id_llanta = data.id_llanta);
                    }catch(e){
                        error.value= e.response.data.error;
                    }
                }
                else{
                    loading.value = false;
                    return
                }
            }
            try{
                await movimientos_service.value.createMovimiento(dataMovimiento.value)
            }catch(e){
                error.value= e.response.data.error;
            }

            if(!nuevaLlanta.value){
                await loadLlantas();
            }
            modificado.value = true;
            dataLlanta.value = JSON.stringify(emptyDataLlanta.value);
            dataLlanta.value = JSON.parse(dataLlanta.value);
            newMarca.value = false;
            loading.value = false;
            posicionesLibres.value = posicionesLibres.value.filter(p=> p!=dataMovimiento.value.posicion_destino)
            dataMovimiento.value.posicion_destino = posicionesLibres.value.length > 0 ? posicionesLibres.value[0] : 0;
            llantaSeleccionada.value=null;
            //showAlert();

        };

        function cambioPagina(obPaginados){
            objetosPaginados.value = obPaginados; 
        }

        function checkRequiredValues(){
                emptyRequireds.value = []; 
                if(dataLlanta.value.serie == null){
                    emptyRequireds.value.push(0);
                }
                if(dataLlanta.value.id_marca_llanta== '' || (newMarca.value && !newMarcaVal.value) ){
                    emptyRequireds.value.push(1);
                }
         };

         function enableMarcaText(){
            newMarca.value = dataLlanta.value.id_marca_llanta==='-1'?true:false;
            setTimeout(() => {
                document.getElementById('marca-text').focus();
            }, 50);
            
        };

        const open = ()=>{
            modificado.value = false;
            dataLlanta.value.serie = null;
            dataLlanta.value.id_marca_llanta = '';
            dataLlanta.value.modelo = null;
            dataLlanta.value.rin = null;
            dataLlanta.value.dimension = null;
            numeroLlantas.value = vehiculo.value.numero_llantas;
            emptyRequireds.value = [];
            nuevaLlanta.value = true;
            getPosicionesLibres();
            let modal = new Modal(document.getElementById('llantas-modal'),{});
            modal.show();
        }
        function close(){
            llantaSeleccionada.value = null;
            context.emit('close', modificado.value);
        }

        context.expose({open});

        return{
            close,
            loading,
            search,
            searchLlanta,
            loadLlantas,
            cancel,
            objetosPaginados,
            totalPaginas,
            totalVistas,
            vehiculosPaginados,
            posicionesLibres,
            savePosicionButton,
            savePosicion,
            emptyRequireds,
            dataLlanta,
            nuevaLlanta,
            currentMarca,
            newMarca,
            newMarcaVal,
            marcasLlanta,
            cambioPagina,
            llantaSeleccionada,
            seleccionLlanta,
            vistaActual,
            paginasVista,
            paginaActual,
            numeroLlantas,
            enableMarcaText,
            error

        }
    }
}
</script>

<style scoped>

    .table-hover tr:hover {
        background:#0033ff41;
        cursor: pointer;
    }

    input[type="search"]::-webkit-search-cancel-button:hover {
        cursor: pointer;
    }
</style>