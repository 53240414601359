import axios from 'axios'

export default class llantasVehiculoService{
    readLlantasVehiculoId(idVehiculo){
        return axios.get(`/llantavehiculoid/${idVehiculo}`).then(response=> response.data.data);
    }

    readLlantasVehiculo(){
        return axios.get('/llantavehiculo').then(response=> response.data.data);
    }
}