import axios from 'axios'

export default class marcasLlantaService{
    readMarcas(){
        return axios.get('/marcallanta').then(response=>response.data.data);
    }

    storeMarca(data){
        return axios.post('/marcallanta',data).then(response=> response.data.data);
    }
}