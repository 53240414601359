import axios from 'axios'

export default class movimientosService{
    createMovimiento(data){
        return axios.post('/movimiento', data).then(response=> response.data.data);
    }
    readMovimientos(){
        return axios.get('/movimiento').then(response=> response.data.data);
    }
    readMovimientosId(id){
        return axios.get(`/movimiento/${id}`).then(response=> response.data.data);
    }

    readTiposMovimiento(){
        return axios.get('/tipomovimiento').then(response=> response.data.data);
    }


}