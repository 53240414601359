import axios from 'axios'

export default class llantasService{
    
    readLlantas(){
        return axios.get('/llanta').then(response => response.data.data)
    }

    readLlantasDisponibles(){
        return axios.get('/llantadisponible').then(response => response.data.data)
    }

    storeLlanta(data){
        return axios.post('/llanta', data).then(response => response.data.data)
    }

    updateLlanta(id, data){
        return axios.put(`/llanta/${id}`, data).then(response => response.data.data)
    }

    searchLlanta(search){
        return axios.get( '/busquedallanta', {
            params: {
              search: search
            }}).then(response=> response.data.data);
    }
    searchLlantaDisponible(search){
        return axios.get( '/busquedallantadisponible', {
            params: {
              search: search
            }}).then(response=> response.data.data);
    }
}