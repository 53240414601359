<template>
  <SideBar>
    <router-view></router-view>
  </SideBar>
</template>

<script>

import SideBar from './components/side-bar.vue'
export default {
  name: 'App',
  components:{
    SideBar
  },
  beforeCreate(){
    if(!this.$store.state.isAuthenticated){
      this.$router.push('/Login')
    }
  }
}
</script>

<style>
    .over-layed{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: rgba(0,0,0,0.4);
        
    }

    .spinner-lg{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 3rem; 
        height: 3rem;
    }

    select{
        cursor: pointer;
    }

    input[type="search"]::-webkit-search-cancel-button:hover {
        cursor: pointer;
    }


</style>