<template>
    <div  class="modal" id="modal-vehiculos">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content"> 
                <div class="modal-header">
                    <h5 class="modal-title">Seleccionar vehículo</h5>
                </div>
                <div class="modal-body">
                    <div v-if="loading" class="d-flex justify-content-center">
                        <div class="spinner-border" role="status"></div>
                    </div>

                    <div v-else>
                        <div  class="row g-0 mb-2 justify-content-between">
                            <div class="col-7">
                                <div class = "input-group input-group-sm mb-0 " >
                                    <input v-model="search"  placeholder="Buscar vehículo" type="search" class="form-control form-control-sm" @input="cancel()"/>
                                    <div class="input-group-append">
                                        <button class="btn btn-secondary btn-sm" type="button" @click="searchVehiculo(search)"><i class="bi bi-search"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto" v-show="!loading && totalPaginas>1">
                                <nav aria-label="..." >
                                    <ul class="pagination pagination-sm justify-content-end mb-0">
                                        <li v-bind:class="{'disabled':vistaActual==1 , 'page-item': true} ">
                                          <a class="page-link" href="javascript:;" @click="vistaActual--"><i class="bi bi-chevron-double-left"></i></a>
                                        </li>

                                        <li v-for="pagina in totalVistas" :key="pagina.id" v-bind:class="{'active': pagina+(paginasVista*(vistaActual-1))==paginaActual, 'page-item':true} "><a class="page-link" href="javascript:;" @click="paginaActual = pagina+(paginasVista*(vistaActual-1))">{{pagina+(paginasVista*(vistaActual-1))}}</a></li>

                                        <li v-bind:class="{'disabled':vistaActual >= (totalPaginas/paginasVista) , 'page-item': true}">
                                          <a class="page-link" href="javascript:;" @click="vistaActual++"><i class="bi bi-chevron-double-right"></i></a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>

                        <table class="table table-striped table-bordered table-sm table-hover mb-0" style="font-size:.9rem;">
                            <thead>
                                <th scope="col">Tipo</th>
                                <th scope="col">Marca</th>
                                <th scope="col">Modelo</th>
                                <th scope="col">No. Económico</th>
                                <th scope="col">VIN</th>
                                <th scope="col">Placa estatal</th>
                                <th scope="col">Placa federal</th>
                            </thead>
                            <tbody>
                                <tr v-for="vehiculo in objetosPaginados.filter(obj=> obj.id_vehiculo !== omitirVehiculo)" v-bind:key="vehiculo" @click="vehiculoSeleccionado = vehiculo; seleccionVehiculo(vehiculo)">
                                    <td>{{vehiculo.tipo_vehiculo}}</td>
                                    <td>{{vehiculo.marca}}</td>
                                    <td>{{vehiculo.modelo}}</td>
                                    <td>{{vehiculo.no_economico}}</td>
                                    <td>{{vehiculo.vin}}</td>
                                    <td>{{vehiculo.placa_estatal}}</td>
                                    <td>{{vehiculo.placa_federal}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                  <button type="button" id="closeModal" class="btn btn-secondary" data-bs-dismiss="modal" @click="paginaActual = 1;vistaActual = 1">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ref ,onMounted, computed} from 'vue';
import { Modal } from 'bootstrap'
import usePaginacion from '../composable/paginacion.js'
import vehiculosService from '../services/vehiculosService.js'


export default {
    name: 'ListaVehiculos',
    props:{
        omitirVehiculo:Number
    },
    setup(props, context) {        
        const loading = ref(false);
        const paginaActual = ref( 1);
        const paginasVista = 8
        const paginacion = ref(new usePaginacion([], 12, paginasVista));
        const search = ref('');
        const searched = ref(false);
        const vistaActual = ref(1);

        const vehiculos_service =ref( new vehiculosService);
        
        const objetosPaginados = computed(() => paginacion.value.paginados(paginaActual.value));
        const totalPaginas = computed(() => paginacion.value.totalPaginas());
        const totalVistas = computed(() => paginacion.value.totalVistas(vistaActual.value));

        onMounted(async () => {
            await vehiculos_service.value.readVehiculos().then(data=>paginacion.value.updateLista(data));
        });

        async function searchVehiculo(search){
            loading.value = true;
            await vehiculos_service.value.searchVehiculo(search).then(data=>paginacion.value.updateLista(data));
            searched.value = true;
            loading.value = false;
        };

        async function cancel(){
            if(!search.value && searched.value){
                loading.value = true;
                await vehiculos_service.value.readVehiculos().then(data=>paginacion.value.updateLista(data));
                loading.value = false;
                searched.value = false;
            }
        };

        function seleccionVehiculo(vehiculo){
            document.getElementById('closeModal').click();
            context.emit('seleccionVehiculo', vehiculo)
            
        };

        const open = ()=>{
            let modal = new Modal(document.getElementById('modal-vehiculos'),{});
            modal.show();
        }

        context.expose({open});

        return{
            cancel,
            loading,
            objetosPaginados,
            paginaActual,
            paginasVista,
            search,
            searchVehiculo,
            seleccionVehiculo,
            totalPaginas,
            totalVistas,
            vistaActual,
            
        }
    }
}
</script>

<style scoped>

    .table-hover tr:hover {
        background:#0033ff41;
        cursor: pointer;
    }

    input[type="search"]::-webkit-search-cancel-button:hover {
        cursor: pointer;
    }
</style>