export default class usePaginacion{
    constructor(lista, elementosPagina, paginasVista){
        this.lista = lista; this.elementosPagina = elementosPagina; this.paginasVista = paginasVista;
    }
    
    updateLista(newLista){
        this.lista = newLista;
    }

    updateNumPaginas(paginas){
        this.elementosPagina = paginas;
    }

    totalPaginas(){
        return Math.ceil(this.lista.length / this.elementosPagina);
    }

    totalVistas(vistaActual){
        return this.paginasVista-Math.max((vistaActual * this.paginasVista) - this.totalPaginas(), 0);
    }

    paginados(paginaActual){
        let ini = (paginaActual * this.elementosPagina) - this.elementosPagina;
        let fin = (paginaActual * this.elementosPagina);
        return this.lista.slice(ini, fin);
    }
}