import axios from 'axios'

export default class usuariosService{
    createUsuario(data){
        return axios.post('/usuario', data).then(response=>[response.status, response.statusText]);
    }
    readUsuarios(){
        return axios.get('/listaUsuario').then(response=>response.data.data);
    }
    readRol(){
        return axios.get('/rol').then(response=>response.data.data);
    }
    updateUsuario(userId, data){
        return axios.put(`/usuario/${userId}`, data).then(response=>[response.status, response.statusText]);
    }
    deleteUsuario(userId){
        return axios.delete(`/usuario/${userId}`).then(response=>[response.status, response.statusText]);
    }
    searchUsuario(search){
        return axios.get( '/busquedaUsuario', {
            params: {
              search: search
            }}).then(response=> response.data.data);
    }
}