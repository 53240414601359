<template>
    <div v-show="$store.state.isAuthenticated">
        <nav class="navbar navbar-expand d-flex flex-column active-nav" id="sidebar">
            <div class="row">
                <h5 class="text-white text-center"><span class="animated-text animated-text-hide">{{displayed? 'APIClix-Llantas':''}}&nbsp;</span><span><a href="javascript:;" v-show="$store.state.isAuthenticated" class="text-white" @click="displaySideBar(); "><i class="bi bi-list" style="font-size: 1.25em;"></i></a></span></h5>
                
            </div>
            <ul class="navbar-nav d-flex flex-column mt-4 w-100">
                <li class="pl-4" v-bind:title="!displayed?'Inicio':''">
                    <router-link class="nav-link ulItem" to="/"> <i class="bi bi-house-door"></i><span class="animated-text animated-text-hide">&nbsp;{{displayed?'Inicio':''}}</span></router-link>
                </li>

                <li v-bind:class="{'nav-item':true, 'py-auto':true }" v-bind:title="!displayed?'Catálogos':''">
                    <a id="catalogos-toggle" href="#" class="nav-link ulItem pl-4':true" data-bs-toggle="collapse" data-bs-target="#collapseCatalogos" aria-expanded="false" aria-controls="collapseCatalogos" @click="!displayed?displaySideBar():''"><span class="mr-4"><i class="bi bi-card-list"></i> </span><span class="animated-text animated-text-hide">&nbsp;{{displayed? 'Catálogos':''}}</span></a>
                    
                    
                    <ul class="collapse w-100 list-unstyled" id="collapseCatalogos">
                        <li class="ulItem"><router-link class="nav-link myRouterLink" to="/Usuarios"><i class="bi bi-person"></i>&nbsp;<span class="collapse-text">Usuarios</span></router-link></li>
                        <li class="ulItem"><router-link class="nav-link myRouterLink" to="/Vehiculos"><i class="bi bi-truck"></i>&nbsp;<span class="collapse-text">Vehículos</span></router-link></li>
                        <li class="ulItem"><router-link class="nav-link myRouterLink" to="/Dispositivos"><i class="bi bi-phone"></i>&nbsp;<span class="collapse-text">Dispositívos</span></router-link></li>
                    </ul>
                </li>

                <li class="pl-4" v-bind:title="!displayed?'Movimientos':''">
                    <router-link class="nav-link ulItem" to="/Movimientos"> <i class="bi bi-arrow-left-right"></i><span class="animated-text animated-text-hide">&nbsp;{{displayed?'Movimientos':''}}</span></router-link>
                </li>

                <li class="pl-4" v-bind:title="!displayed?'Mediciones':''">
                    <router-link class="nav-link ulItem" to="/Mediciones"> <i class="bi bi-speedometer2"></i><span class="animated-text animated-text-hide">&nbsp;{{displayed?'Mediciones':''}}</span></router-link>
                </li>

                <li class="nav-item " v-bind:title="!displayed?'Registros':''">
                    <a id="registros-toggle" href="#" class="nav-link ulItem pl-4"  data-bs-toggle="collapse" data-bs-target="#collapseRegistros" aria-expanded="false" aria-controls="collapseRegistros" @click="!displayed?displaySideBar():''"><i class="bi bi-folder2"></i><span class="animated-text animated-text-hide">&nbsp;{{displayed?'Registros':''}}</span></a>
                    <ul class="collapse w-100 list-unstyled" id="collapseRegistros">
                        <!--<li class="ulItem"><router-link class="nav-link myRouterLink" to="/Logs"><i class="bi bi-files"></i>&nbsp;<span class="collapse-text">Logs</span></router-link></li>-->
                        <li class="ulItem"><router-link class="nav-link myRouterLink" to="/Alertas"><i class="bi bi-bell"></i>&nbsp;<span class="collapse-text">Alertas</span></router-link></li>
                    </ul>
                </li>

                <li class="nav-item " v-bind:title="!displayed?'Reportes':''">
                    <a id="reportes-toggle" href="#" class="nav-link ulItem pl-4"  data-bs-toggle="collapse" data-bs-target="#collapseReportes" aria-expanded="false" aria-controls="collapseReportes" @click="!displayed?displaySideBar():''"><i class="bi bi-clipboard2"></i><span class="animated-text animated-text-hide">&nbsp;{{displayed?'Reportes':''}}</span></a>
                    <ul class="collapse w-100 list-unstyled" id="collapseReportes">
                        <li class="ulItem"><router-link class="nav-link myRouterLink double-line-cont" to="/Estadisticas">
                            <i class="bi bi-clipboard2-data double-line"></i>&nbsp;<span class="collapse-text double-line">Estadísticas<br>de vehículo</span></router-link>
                        </li>
                        <li class="ulItem"><router-link class="nav-link myRouterLink double-line-cont" to="/HistorialMovimientos"><i class="bi bi-clock double-line"></i>&nbsp;<span class="collapse-text double-line">Historial de<br> movimientos</span></router-link></li>
                    </ul>
                </li>
            </ul>
            <div class="w-100 pl-4 mt-auto" v-bind:title="!displayed?'Cerrar sesión':''">
                    <a href="#" class="dropdown-item p-2 nav-link ulItem pl-4" @click="$store.commit('logout')"><i class="bi bi-box-arrow-left"></i><span class="animated-text animated-text-hide">&nbsp;{{displayed?'Cerrar sesión':''}}</span></a>
            </div>
            
        </nav>
    </div>
    <section class="p-4 my-container active-cont" id="my-container">
        <slot></slot>
    </section>

</template>

<script ></script>

<script>
import { flip } from '@popperjs/core';
export default {
    name: 'SideBar',
    data() {
        return {
            displayed:false,
        }
    },
    methods:{
        displaySideBar(){
            var animatedText = document.getElementsByClassName('animated-text');
            for(var i=0; i<animatedText.length; i++){
                if(animatedText[i].classList.contains('animated-text-show')){
                    animatedText[i].classList.replace('animated-text-show', 'animated-text-hide');
                } else{
                    animatedText[i].classList.replace('animated-text-hide', 'animated-text-show');
                }
            }
            var collapseText = document.getElementsByClassName('collapse-text');
            for(var i=0; i<collapseText.length; i++){
                    collapseText[i].classList.add('animated-text-show',);
            }

            var sidebar = document.querySelector("#sidebar");
            var container = document.querySelector("#my-container");
            var catalagos = document.getElementById("collapseCatalogos");
            var catalagosToggle = document.getElementById("catalogos-toggle");
            var registrosToggle = document.getElementById("registros-toggle");
            var reportesToggle = document.getElementById("reportes-toggle");
            var registros = document.querySelector("#collapseRegistros");
            var reportes = document.querySelector("#collapseReportes");

            if(this.displayed && catalagos.classList.contains('show')){
                    catalagosToggle.setAttribute('aria-expanded', false);
                    catalagos.classList.remove('show');
            }
            if(this.displayed && registros.classList.contains('show')){
                    registrosToggle.setAttribute('aria-expanded', false);
                    registros.classList.remove('show');
            }if(this.displayed && reportes.classList.contains('show')){
                    reportesToggle.setAttribute('aria-expanded', false);
                    reportes.classList.remove('show');
            }
            catalagosToggle.classList.toggle('dropdown-toggle');
            registrosToggle.classList.toggle('dropdown-toggle');
            reportesToggle.classList.toggle('dropdown-toggle');

            sidebar.classList.toggle("active-nav");
            container.classList.toggle("active-cont");
            setTimeout(() => {
                this.displayed = !this.displayed;


            }, this.displayed? 500:0);

            setTimeout(()=>{
                for(var i=0; i<collapseText.length; i++){
                    collapseText[i].classList.remove('animated-text-show',);
                }
            }, 500)
        },
    }
}


</script>

<style scoped>
    h5{
        overflow: hidden;
        white-space: nowrap;
        font-size: 1.125em;
    }
    .navbar{
        width: 162px;
        height: 100vh;
        position: fixed;
        margin-left: 0px ;
        background-color: #313A46;
        transition: .5s;
        text-align: start;
    }
    section{
        height: 100vh;
        background: #F3F4F8;
    }
    .cont{
        width: 50px;
        height: 100vh;
        position: fixed;
        margin-left: 0px ;
        background-color: grey;
        transition: .5s;
    }
    .nav-link{
        font-size: 1.125em;
        color: white;
        text-decoration: none;
        overflow: hidden;
        white-space: nowrap;
    }
    .ulItem:active,
    .ulItem:focus,
    .ulItem:hover{
        background-color: #ffffff26;
    }
    .my-container{
        transition: .5s;
        margin-left: 162px;

    }
    .active-nav{
        width: 45px;
        text-align: center;
    }
    .active-cont{
        margin-left:45px;
    }
    .hide-nav{
        width: 0px;
        text-align: center;
    }
    .full-cont{
        margin-left:0px;
    }
    .myRouterLink{
        margin-left: 18px;
        font-size: .99em;
        text-decoration: none;
        color:white;
    }
    .dropdown-toggle[aria-expanded="true"]:after {
    transform: rotate(180deg); 
    }
    /*for animation*/ 
    .dropdown-toggle:after { 
    transition: 0.4s; 
    }

.animated-text-show {
  display: inline-block;
  vertical-align: bottom;
  max-width: 20rem;
  overflow: hidden;
  white-space: nowrap;
  animation: slow 1.5s ease-in-out forwards;
}

.animated-text-hide {
  display: inline-block;
  vertical-align: bottom;
  max-width: 0rem;
  overflow: hidden;
  white-space: nowrap;
  animation: slow-reverse .5s ease-in-out backwards;
}
    .double-line{
  display: table-cell;
  vertical-align: top;
}

.double-line-cont{
    display:table;
}

@keyframes slow {
  from {
    max-width: 0%;
  }
  to {
    max-width: 100%;
  }
}

@keyframes slow-reverse {
  from {
    max-width: 100%;
  }
  to {
    max-width: 0%;
  }
}


</style>