<template>
    <div class="container-fluid h-100">
        <div class="d-flex flex-column h-100">
            <div class="row align-items-center justify-content-between px-0 g-0 mb-2">
                <div class="col-6">
                    <h4>Usuarios</h4>
                </div>
                <div v-show="tableLoaded " class="col-auto">
                    <nav aria-label="..." v-show="totalPaginas>1">
                        <ul class="pagination pagination-sm justify-content-end mb-0">
                            <li v-bind:class="{'disabled':vista==1 , 'page-item': true} ">
                              <a class="page-link" href="javascript:;" @click="vista--"><i class="bi bi-chevron-double-left"></i></a>
                            </li>
                            <li v-for="pagina in paginasVista-Math.max((vista*paginasVista)-totalPaginas, 0)" :key="pagina.id" v-bind:class="{'active': pagina+(paginasVista*(vista-1))==paginaActual, 'page-item':true} "><a class="page-link" href="javascript:;" @click="paginaActual = pagina+(paginasVista*(vista-1));getDataPagina(pagina+(paginasVista*(vista-1)))">{{pagina+(paginasVista*(vista-1))}}</a></li>
                            
                            <li v-bind:class="{'disabled':vista >= (totalPaginas/paginasVista) , 'page-item': true}">
                              <a class="page-link" href="javascript:;" @click="vista++"><i class="bi bi-chevron-double-right"></i></a>
                            </li>
                            <!--<li v-bind:class="{'disabled':paginaActual<=paginasVista , 'page-item': true} ">
                              <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Anterior</a>
                            </li>
                            <li v-for="pagina in totalPaginas" :key="pagina.id" v-bind:class="{'active': pagina==paginaActual, 'page-item':true} "><a class="page-link" href="#" @click="paginaActual = pagina;getDataPagina(pagina)">{{pagina}}</a></li>
                            <li v-bind:class="{'disabled':paginaActual> Math.round(totalPaginas()/paginasVista)*paginasVista , 'page-item': true}">
                              <a class="page-link" href="#">Siguiente</a>
                            </li>-->
                        </ul>
                    </nav>
                </div>
            </div>


            <div class="row justify-content-between px-0 g-0 mb-2" v-show="tableLoaded">
                <div class="col-8">
                    <div class = "input-group input-group-sm mb-0 " >
                        <input v-model="search" v-show="tableLoaded" type="search" class="form-control form-control-sm" @input="cancel()"/>
                        <div class="input-group-append">
                            <button class="btn btn-secondary btn-sm" type="button" @click="searchUsuario(search)"><i class="bi bi-search"></i></button>
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <div class="dropdown px-2">
                        <button class="btn btn-sm btn-light btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi bi-table"></i>                                
                        </button>
                            <ul class="dropdown-menu p-2" aria-labelledby="dropdownMenuButton1">
                              <li v-for="header in tableHeaders" :key=" header">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" v-model="header[2]">
                                    <label class="form-check-label" >
                                      {{header[1]}}
                                    </label>
                                </div>
                              </li>
                            </ul>
                    </div>
                </div>
                <div class="col-2 text-end">
                    <button class="btn btn-success btn-sm" data-bs-toggle="modal" data-bs-target="#openModal" data-keyboard="false" data-backdrop="static" @click="edit = false;initializeValues();emptyRequireds=[]"><i class="bi bi-plus-lg" ></i> Agregar Usuario</button>
                </div>
            </div>
            <div v-show="loading" class="over-layed">
                <div class="spinner-border spinner-lg" role="status"></div>
            </div>

             <div class="table-responsive flex-grow-1 mb-0"  ref="containerRef">
                <table v-show="tableLoaded" class="table table-bordered table-hover table-sm" style="white-space: nowrap;  font-size:.9rem;">
                    <thead>
                        <th v-for="header in tableHeaders.filter(header => header[2] === true)" :key="header" @click="ordenar(header[0])">
                            {{header[1]}}
                            <span v-if="header[0] === currentColumn">
                                <i :class="[orden ==='asc' ? 'bi bi-chevron-up' : 'bi bi-chevron-down' ]"></i>
                            </span>
                        </th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </thead>
                    <tbody>
                        <tr v-for="(usuario, index) in usuariosPaginados" v-bind:key="usuario" v-bind:class="{'table-secondary': usuario.fecha_reg !== usuario.fecha_mod}">
                            <td v-for="header in tableHeaders.filter(header => header[2] === true)"  :key="header" v-bind:class="{'text-center':header[0]=='activo'}" v-bind:title="header[0].includes('fecha')? usuario[header[0].replace('fecha', 'usuario')]:''">
                                {{header[0]!= 'activo'? header[0].includes('fecha')? formatDate(usuario[header[0]]):usuario[header[0]]:''}} <i class="bi bi-check" v-show="usuario.activo" v-if="header[0] == 'activo'" ></i>
                            </td>
                            <td style="text-align: center"><a href="#!"  data-bs-toggle="modal" data-bs-target="#openModal" @click="edit = true;idUsuario=usuario.id_usuario;editar(index);emptyRequireds=[]" ><i class="bi bi-pencil-fill"></i></a></td>
                            <td style="text-align: center"><a href="#!"  data-bs-toggle="modal" data-bs-target="#modalEliminar" @click="idUsuario = usuario.id_usuario, elIndex = index "><i class="bi bi-trash3-fill"></i></a></td>
                        </tr>
                    </tbody>   
                </table>
            </div>
        </div>

        <div id="toastAlert" class="toast bg-secondary text-white position-fixed bottom-0 end-0 m-3" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    {{alertMessage}}
                    <div class="mt-2 pt-2 border-top" v-show="constraint">
                      <button type="button" class="btn btn-primary btn-sm" @click="desactivar()">Desactivar</button>
                    </div>
                </div>
                <button type="button" v-bind:class="{'btn-close btn-close-white me-2 m-auto':true, 'mt-2':constraint}" data-bs-dismiss="toast" aria-label="Close" @click="constraint = false"></button>
            </div>
        </div>
    </div>



    <div  class="modal" id="openModal">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content"> 
                <div class="modal-header">
                    <h5 class="modal-title">Formulario de usuario</h5>
                </div>

                <div class="modal-body">
                    <div class="row justify-content-center">
                        <div class="row">
                            <div class="col">
                                <div>Nombre*</div>
                                <input type="text" maxlength="50" style="width: 100%" v-model="data.nombre" v-bind:class="{'form-control':true, 'is-invalid':emptyRequireds.includes(0)}" required>
                                <div class="invalid-feedback"> El nombre es requerido </div>
                            </div>
                        </div>
                        

                        <div class="row ">
                            <div class="col-5">
                                <div>Usuario*</div>
                                <input type="text" maxlength="50" style="width: 100%" v-model="data.usuario" v-bind:class="{'form-control':true, 'is-invalid':emptyRequireds.includes(1)}" required spellcheck="false">
                                <div class="invalid-feedback"> El usuario es requerido </div>
                            </div>

                            <div class="col-auto">
                                <div>Rol*</div>
                                <select v-bind:class="{'form-select':true, 'is-invalid':emptyRequireds.includes(2)}" v-model="data.id_rol" required>
                                    <option selected disabled value="">Seleccionar rol</option>
                                    <option v-for="r in rols" v-bind:key="r" v-bind:value="r.id_rol">{{r.nombre}}</option>
                                </select>
                                <div class="invalid-feedback">El rol es requerido</div>  
                            </div>

                            <div class="col-auto align-self-end">
                                <div class="form-check pb-1">
                                    <input class="form-check-input" type="checkbox" v-model="data.activo">
                                    <label class="form-check-label" > Activo </label>
                                </div>
                            </div> 
                            
                        </div>

                        <div class="row">
                            <div class="col">
                                <div>Correo*</div>
                                <input type="text" maxlength="50" style="width: 100%" v-model="data.email" v-bind:class="{'form-control':true, 'is-invalid':emptyRequireds.includes(3)}" required>
                                <div class="invalid-feedback"> El correo es requerido </div>
                            </div>
                        </div>

                        <div class="row " >
                            <div class="col-6">
                                <div>Contraseña*</div>
                                <input id="passwordField" v-bind:type="inputTipe" minlength="8" style="width: 100%" v-bind:class="{'form-control':true, 'is-invalid':emptyRequireds.includes(4)}" v-bind:disabled="!changeContrasena && edit" v-bind:placeholder="!changeContrasena && edit?'**********':''" spellcheck="false" v-bind:required="!edit" v-model="data.contrasena" @keyup="keyupEvent()">
                                <div class="invalid-feedback"> {{minLengthError? 'La contraseña debe tener al menos 8 caracteres' : 'La contraseña es requerida'}} </div>
                            </div>

                            <div class="col-6" v-show="changeContrasena || !edit">
                                <div>Confirmar contraseña*</div>
                                <div class="input-group">
                                    <input id="confirmPasswordField" v-bind:type="inputTipe"  v-bind:class="{'form-control':true, 'is-invalid':emptyRequireds.includes(5)}" spellcheck="false" required v-model="confirmContrasena" @keyup="keyupEvent()">

                                    <div class="input-group-prepend watch-button" @click="verContrasena = !verContrasena">
                                        <div class="input-group-text">
                                            <i v-bind:class="{'bi':true, 'bi-eye-fill':!verContrasena,'bi-eye-slash-fill':verContrasena}"></i>
                                        </div>
                                    </div>

                                    <div class="invalid-feedback"> La contraseña no coincide </div>
                                </div>
                            </div>
                            <div class="col-6 align-self-end" v-show="!changeContrasena && edit">                                
                                <button class="btn btn-success" style="width:100%" @click="changePassword()">Cambiar contraseña</button>                                
                            </div>
                        </div>

                        <div class="row " v-show="changeContrasena && edit">
                            <div class="col">                                
                                <button class="btn btn-success" style="width:100%" @click="changePassword()">Cancelar cambio de contraseña</button>                                
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="modal-footer">
                  <button type="button" id="closeModal" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                  <button id="saveButton" form="myForm" class="btn btn-primary" @click.stop="upload()" :disabled="hasChanged">Guardar {{edit ? 'cambios' : 'nuevo usuario'}}</button>
                </div>
            </div>
        </div>
    </div>
    
    <div  class="modal" id="modalEliminar">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content"> 
                <div class="modal-header">
                    <h5 class="modal-title">Eliminar medición</h5>
                </div>
                <div class="modal-body">
                    ¿Está seguro que desea eliminar el vehículo?
                </div>

                <div class="modal-footer">
                  <button type="button" id="closeModalDel" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                  <button type="button" id="saveButton" class="btn btn-success" @click="deleteUsuario(idUsuario);">Eliminar</button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import usuariosService from '../services/usuariosService.js'
export default {
    name:'Usuarios',
    data() {
        return {
            rols:[],
            usuarios:[],
            usuariosPaginados:[],

            emptyRequireds:[],
            idUsuario:null,
            elIndex: null,

            tableHeaders:[
                ['nombre','Nombre ',true],
                ['usuario','Usuario',true],
                ['email','Correo',true],
                ['rol','Rol',true],
                ['activo','Activo',true],
                ['fecha_reg','Fecha de registro',false],
                ['usuario_reg','Registró',false],
                ['fecha_mod','Fecha de modificación',false],
                ['usuario_mod','Modificó',false],
            ],
            orden:'asc',
            currentColumn:null,
            search:'',
            searched: false,

            alertMessage: '',

            loading:false,
            changeContrasena:false,
            tableLoaded: false,
            elementosPagina: 17,
            paginaActual: 1,
            paginasVista:10,
            vista:1,

            contrasena:'',
            confirmContrasena: '',
            verContrasena: false,
            confirmarContrasenaField: false,
            minLengthError:false,
            height:0,
            
            edit: false,

            data:{
                nombre: "",
                usuario: "",
                email: "",
                id_rol:"",
                activo: true,
                contrasena:'',
                id_usuario_mod: localStorage.getItem('user_id'),

            },

            oldData:{
                nombre: "",
                usuario: "",
                email: "",
                id_rol:"",
                activo: true,
                contrasena:'',
                id_usuario_mod: localStorage.getItem('user_id'),
            },

            changed: false,
            constraint: false,
            timer: undefined,
        }   
    },
    methods:{
        async searchUsuario(search){
            this.loading = true;
            await this.usuariosService.searchUsuario(search).then(data=>this.usuarios = data);
            //this.ordenar(this.currentColumn, 0);
            this.getDataPagina(this.paginaActual);
            this.searched = true;
            this.loading = false;
        },
        
        getDataPagina(noPagina){
            this.usuariosPaginados = [];
            let ini = (noPagina*this.elementosPagina) - this.elementosPagina;
            let fin = (noPagina * this.elementosPagina);
            this.usuariosPaginados = this.usuarios.slice(ini, fin);
        },
        async loadData(){
            this.loading = true;
            await this.usuariosService.readUsuarios().then(data=>this.usuarios = data);
            this.ordenar(this.currentColumn, 0);
            this.getDataPagina(this.paginaActual);
            this.tableLoaded = true;
            this.loading = false;
        },
        async upload(){
            this.checkRequiredValues();
            if (this.emptyRequireds.length === 0 && !this.minLengthError){
                document.getElementById('closeModal').click();
                this.loading = true;
                try{
                    if(this.edit){
                        await this.usuariosService.updateUsuario(this.idUsuario, this.data);
                    }else{
                        await this.usuariosService.createUsuario(this.data);
                    }
                    this.loadData();
                    this.alertMessage = this.edit? 'Actualizado con exito':'Creado con exito';

                }catch(e){
                    this.alertMessage = e.response.data.error;
                    var toast = document.querySelector("#toastAlert");
                    toast.classList.remove("bg-secondary");
                    toast.classList.add("bg-danger");
                }
                this.loading = false;
                this.showAlert();
            }
        },
        async deleteUsuario(id){
            document.getElementById('closeModalDel').click();
            this.loading = true;
            try{
                await this.usuariosService.deleteUsuario(id);
                await this.loadData();
                this.alertMessage = 'Eliminado con exito';
            }catch(e){
                this.alertMessage = e.response.data.error;
                if(this.alertMessage.includes('Cannot delete or update')){
                    this.alertMessage = 'El elemento que desea eliminar cuenta con información registrada, por lo que solo puede ser desactivado.'
                    if(this.usuarios[this.elIndex].activo){
                        this.constraint = true;
                    }
                }else{
                    var toast = document.querySelector("#toastAlert");
                    toast.classList.remove("bg-secondary");
                    toast.classList.add("bg-danger");
                }
                
            }
           
            this.loading = false;
            this.showAlert();

            if(this.paginaActual>this.totalPaginas){
                this.paginaActual=this.totalPaginas;
                this.getDataPagina(this.paginaActual);
            }
        },

        async editar(index){
            if(this.data.hasOwnProperty('id_usuario_reg')){
                delete this.data['id_usuario_reg'];
                delete this.oldData['id_usuario_reg'];
            }
            let passField = document.getElementById('passwordField');
            if(passField.classList.contains('is-invalid')){
                passField.classList.remove( "is-invalid" );
            }

            var usuario = this.usuarios[index+((this.paginaActual-1)*this.elementosPagina)];
            delete this.data["contrasena"];
            delete this.oldData["contrasena"];
            this.confirmContrasena = '';
            this.minLengthError = false;
            this.oldData.nombre = this.data.nombre = usuario.nombre;
            this.oldData.usuario = this.data.usuario = usuario.usuario;
            this.oldData.email = this.data.email = usuario.email;
            this.oldData.id_rol = this.data.id_rol = this.rols.find(r=>r.nombre == usuario.rol).id_rol;
            this.oldData.activo = this.data.activo = usuario.activo;
            document.getElementById("passwordField").value = '';
            document.getElementById("confirmPasswordField").value = '';
            this.changeContrasena = false;
            this.verContrasena = false;
        },

        desactivar(){
            this.editar(this.elIndex);
            this.data.activo = false;
            this.edit = true;
            this.upload();
            this.constraint = false;
            var toast = document.querySelector("#toastAlert");
            toast.classList.remove('show');
        },

        initializeValues(){
            if(!this.data.hasOwnProperty('contrasena')){
                this.data["contrasena"] = '';
                this.oldData["contrasena"] = '';
            }
            let passField = document.getElementById('passwordField');
            if(passField.classList.contains('is-invalid')){
                passField.classList.remove( "is-invalid" );
            }
            this.minLengthError = false;
            this.oldData.nombre = this.data.nombre = '';
            this.oldData.usuario = this.data.usuario = '';
            this.oldData.email = this.data.email = '';
            this.oldData.id_rol = this.data.id_rol = '';
            this.oldData.activo = this.data.activo = true;
            this.oldData.contrasena = this.data.contrasena = '';
            this.confirmContrasena = '';
            this.verContrasena = false;
            this.oldData.id_usuario_reg = this.data.id_usuario_mod;
            this.data.id_usuario_reg = this.data.id_usuario_mod;
        },

        changePassword(){
            document.getElementById("passwordField").value = '';
            document.getElementById("confirmPasswordField").value = '';
            this.confirmContrasena = '';
            this.emptyRequireds = this.emptyRequireds.filter(function(e) { return e !== 5 && e!==4 });
            this.changeContrasena = !this.changeContrasena;
            if(!this.changeContrasena && this.data.hasOwnProperty('contrasena')){
                delete this.data["contrasena"];
                delete this.oldData["contrasena"];
            }
            else if(this.changeContrasena && !this.data.hasOwnProperty('contrasena')){
                this.data["contrasena"] = '';
                this.oldData["contrasena"] = '';
            }
        },

        comparePassword(){
            return this.confirmContrasena === this.data.contrasena;
        },

        checkRequiredValues(){
                this.emptyRequireds = []; 
                if(this.data.nombre == ''){
                    this.emptyRequireds.push(0);
                }
                if(this.data.usuario== ''){
                    this.emptyRequireds.push(1);
                }
                if(this.data.id_rol== ''){
                    this.emptyRequireds.push(2);
                }
                if(this.data.email== ''){
                    this.emptyRequireds.push(3);
                }

                if(this.data.hasOwnProperty('contrasena') && this.data.contrasena == ''){
                    this.emptyRequireds.push(4);
                }
                if(this.data.hasOwnProperty('contrasena') && this.data.contrasena!='' && !this.comparePassword()){
                    this.emptyRequireds.push(5);
                }
         },

        showAlert(){
            if(this.loading){
                setTimeout(this.showAlert, 50);
                return;
            }

            var toast = document.querySelector("#toastAlert");
            toast.classList.add('show');
            if(this.constraint === false){
                setTimeout(() => {
                    toast.classList.remove('show');
                    if(toast.classList.contains("bg-danger")){
                      toast.classList.remove("bg-danger");
                      toast.classList.add("bg-secondary");
                    }
                }, 3000);
            }

        },

        keyupEvent(){
            clearTimeout(this.timer);
            this.timer=setTimeout(()=>{

                let passField = document.getElementById('passwordField');
                if(this.data.contrasena.length<8){
                    this.minLengthError = true;
                    passField.classList.add( "is-invalid" );
                }else{
                    if(passField.classList.contains('is-invalid')){
                         passField.classList.remove( "is-invalid" );
                    }
                }
                
                if(this.confirmarContrasenaField || this.confirmContrasena){
                    this.confirmarContrasenaField = true;
                    if(!this.comparePassword()){
                        this.emptyRequireds.push(5);
                    }
                    else{
                    this.emptyRequireds = this.emptyRequireds.filter(function(e) { return e !== 5});
                    }
                }
            },500);
        },

        ordenar(elemento, auto){
            if (auto == null){
                if (this.currentColumn === elemento){
                    this.currentColumn = this.orden ==='desc'? null:elemento;
                    this.orden = this.orden === 'asc'?'desc':'asc'; 
                }else{
                    this.orden = 'asc';
                    this.currentColumn = elemento;
                }
            }
            this.usuarios.sort(this.compare);
            this.getDataPagina(this.paginaActual);
            
        },
        compare(a,b){
                var value = this.currentColumn??'fecha_reg';
                const varA = typeof a[value] === "string"
                      ? a[value].toUpperCase() : a[value];
                const varB = typeof b[value] === "string"
                      ? b[value].toUpperCase() : b[value];
                let comparison = 0;
                if (varA > varB) comparison = 1;
                else if (varA < varB) comparison = -1;
                return this.orden === "desc" ? comparison * -1 : comparison;
        },
        formatDate(date){
            if(date){
            var date = new Date(date);
            var aaaa = date.getFullYear();
            var mm = String(date.getMonth() + 1).padStart(2,'0');
            var dd = String(date.getDate()).padStart(2,'0');
            var hh = String(date.getHours()).padStart(2,'0');
            var Mm = String(date.getMinutes()).padStart(2,'0');
            var ss = String(date.getSeconds()).padStart(2,'0');
            return (dd + '-' + mm + '-' + aaaa + ' ' + hh + ':'+ Mm);
            } 
            return '';
        },
        cancel(){
            if(!this.search && this.searched){
                this.loadData();
                this.searched = false;
            }
        }
    },
    computed:{
        totalPaginas(){
            return Math.ceil(this.usuarios.length / this.elementosPagina)
        },
        hasChanged(){
            if(JSON.stringify(this.data)!== JSON.stringify(this.oldData) ){
                return false;
            }
            return true;
        },
        inputTipe(){
            return this.verContrasena? 'text':'password';
        },
    },

    usuariosService: null,

    created(){
        this.usuariosService = new usuariosService;
    },

    async mounted(){
        this.loading = true;
        await this.usuariosService.readRol().then(data=>this.rols = data);
        setTimeout(() => {
            this.height = this.$refs.containerRef.clientHeight;
            this.elementosPagina = parseInt((this.height - 60)/31.1);
        }, 20);
        await this.loadData();
    }

}

</script>

<style scoped>
    .form-check-input:hover{
        cursor: pointer;
    }
    .watch-button:hover{
        cursor: pointer;
    }
    th:hover{
    cursor: pointer;
    }
    input[type="search"]::-webkit-search-cancel-button:hover {
      cursor: pointer;
    }
</style>