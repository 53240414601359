import axios from 'axios'

export default class dispositivosService{
    createDispositivo(data){
        return axios.post('/dispositivo2',data).then(response=>response.data.data)
    }
    readDispositivos(){
        return axios.get('/dispositivo').then(response=>response.data.data)
    }
    updateDispositivo(id, data){
        return axios.put(`/dispositivo2/${id}`, data).then(response=>response.data.data)
    }
    deleteDispositivo(id){
        return axios.delete(`/dispositivo/${id}`).then(response=>response.data.data)
    }
    searchDispositivo(search){
        return axios.get( '/busquedaDispositivo', {
            params: {
              search: search
            }}).then(response=> response.data.data);
    }
}