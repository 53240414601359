<template>
    <div>
        Logs
    </div>
</template>

<script>
export default {
    name:'Logs'
}
</script>