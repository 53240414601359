<template>
<div class="container-fluid h-100">
    <div class="d-flex flex-column h-100">
        <div class="row g-0 align-items-center">
            <div class="row align-items-center justify-content-between px-0 g-0 mb-2">
                <div class="col-6">
                    <h4>Mediciones</h4>
                </div>

                <div class="col-auto" v-show="!tableLoaded">
                    <button type="button" class="btn btn-success btn-sm mt-auto" data-bs-toggle="modal" data-bs-target="#openModal">Seleccionar vehículo...</button>
                </div>

                <div  class="col-auto" v-show="totalPaginas>1">
                    <nav aria-label="..." >
                        <ul class="pagination pagination-sm justify-content-end mb-0">
                                <li v-bind:class="{'disabled':vista==1 , 'page-item': true} ">
                                  <a class="page-link" href="javascript:;" @click="vista--"><i class="bi bi-chevron-double-left"></i></a>
                                </li>

                                <li v-for="pagina in paginasVista-Math.max((vista*paginasVista)-totalPaginas, 0)" :key="pagina.id" v-bind:class="{'active': pagina+(paginasVista*(vista-1))==paginaActual, 'page-item':true} "><a class="page-link" href="javascript:;" @click="paginaActual = pagina+(paginasVista*(vista-1))">{{pagina+(paginasVista*(vista-1))}}</a></li>

                                <li v-bind:class="{'disabled':vista >= (totalPaginas/paginasVista) , 'page-item': true}">
                                  <a class="page-link" href="javascript:;" @click="vista++"><i class="bi bi-chevron-double-right"></i></a>
                                </li>
                            </ul>
                    </nav>
                </div>
            </div>
        </div>

        <div class="row g-0 mt-0 justify-content-between rounded p-2" v-show="tableLoaded" style="background-color: lightGrey;">
            <div class="col-auto">
                <h5 class="mb-0">
                    {{vehiculoSeleccionado?(vehiculoSeleccionado.tipo_vehiculo + '  ' + vehiculoSeleccionado.marca + '    ' + vehiculoSeleccionado.modelo):''}}
                </h5>
            </div>
            <div class="col-auto">
                <h5 class="mb-0">{{vehiculoSeleccionado?'No. económico ' + vehiculoSeleccionado.no_economico:''}}</h5>
            </div>
            <div class="col-auto">
                <h5 class="mb-0">{{vehiculoSeleccionado?'Placa estatal: ' + vehiculoSeleccionado.placa_estatal:''}}</h5>
            </div>
            <div class="col-auto">
                <h5 class="mb-0">{{vehiculoSeleccionado?(vehiculoSeleccionado.placa_federal?'Placa federal: ' + vehiculoSeleccionado.placa_federal:''):''}}</h5>
            </div>
        </div>

        <div class="row g-2 my-1"  v-show="tableLoaded">
            <div class="col-auto">
                <label>Fecha inicial</label>
                <input id="el1" v-bind:max="today" type="date" v-model="fechaInicial" class="form-control form-control-sm">
            </div>

            <div class="col-auto">
                <label>Fecha final</label>
                <input type="date" v-bind:max="today" v-model="fechaFinal" class="form-control form-control-sm">
            </div>
            
            <div class="col-auto">
                <label>Tipo de medición</label>
                <select class="form-select form-select-sm" v-model="tipoMedicion">
                    <option selected value="">Todas</option>
                    <option v-for="tipo in tiposMedicion" v-bind:key="tipo" v-bind:value="tipo.id_tipo_medicion">{{tipo.nombre}}</option>
                </select>
            </div>
            <div class="col-auto">
                <label>Posición	</label>
                <select class="form-select form-select-sm" v-model="posicion">
                    <option selected value="">Todas</option>
                    <option v-for="llanta in numeroLlantas" v-bind:key="llanta" v-bind:value="llanta">{{llanta}}</option>
                </select>
            </div>

            <div class="col-auto align-self-end">
                <div class="dropdown">
                    <button class="btn btn-sm btn-light btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="bi bi-table"></i>                                
                    </button>
                        <ul class="dropdown-menu p-2" aria-labelledby="dropdownMenuButton1">
                          <li v-for="header in tableHeaders" :key=" header">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" v-model="header[2]">
                                <label class="form-check-label" >
                                  {{header[1]}}
                                </label>
                            </div>
                          </li>
                        </ul>
                </div>
            </div>
            
            <div class="col text-end align-self-end">
                <button type="button" class="btn btn-success btn-sm mt-auto" data-bs-toggle="modal" data-bs-target="#openModal">Seleccionar vehículo...</button>
            </div>

            <div class="col-auto text-end align-self-end">
                <button type="button" class="btn btn-success btn-sm mt-auto" data-bs-toggle="modal" data-bs-target="#modalMedicion" @click="edit=false;initializeValues();emptyRequireds=[]"><i class="bi bi-plus-lg" ></i> Agregar Medición</button>
            </div>
        </div>

       
            <div v-show="loading" class="over-layed">
                <div class="spinner-border spinner-lg" role="status">
                </div>
            </div>
            
            <div class="table-responsive flex-grow-1 mb-0"  ref="containerRef">
                <table v-show="tableLoaded" class="table table-hover table-sm table-bordered" style="white-space: nowrap; font-size:.9rem;">
                    <thead>
                        <th v-for="header in tableHeaders.filter(header => header[2] === true)" :key="header" @click="ordenar(header[0])">
                            {{header[1]}}
                            <span v-if="header[0] === currentColumn">
                                <i :class="[orden ==='asc' ? 'bi bi-chevron-up' : 'bi bi-chevron-down' ]"></i>
                            </span>
                        </th>
                        <th scope="col" colspan="2" class="text-center"> 
                            <!--<a href="#!"  data-bs-toggle="modal" data-bs-target="#modalMedicion" @click="idVehiculo=vehiculo.id_vehiculo;medicion=medicion.valor" ><i class="bi bi-arrow-clockwise bi-lg"></i></a>-->
                        </th>

                    </thead>
                    <tbody>
                        <tr v-show="filtroMediciones.length==0" >
                            <td align="center" colspan="7">No existen registros coincidentes</td>
                        </tr>
                        <tr v-for="medicion in medicionesPaginadas" v-bind:key="medicion" v-bind:class="{'table-secondary': medicion.fecha_reg !== medicion.fecha_mod || medicion.manual}">
                             <td v-for="header in tableHeaders.filter(header => header[2] === true)"  :key="header" v-bind:title="header[0] === 'fecha_mod'? medicion.usuario_mod: header[0] === 'fecha_reg'? medicion.usuario_reg:''">
                                {{header[0]=='valor'? medicion[header[0]].slice(0, -2) + ' ' + medicion.unidad_medida :header[0].includes('fecha')? formatDate(medicion[header[0]]): medicion[header[0]]}} 
                            </td>
                            <td style="text-align: center"><a href="#!"  data-bs-toggle="modal" data-bs-target="#modalMedicion" @click="edit = true; editar(medicion);" ><i class="bi bi-pencil-fill"></i></a></td>
                            <td style="text-align: center"><a href="#!"  data-bs-toggle="modal" data-bs-target="#modalEliminar" @click="delId = medicion.id_medicion"><i class="bi bi-trash3-fill"></i></a></td>
                        </tr>
                    </tbody>   
                </table>
            </div>

        <div id="toastAlert" class="toast bg-secondary text-white position-fixed bottom-0 end-0 m-3" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    {{alertMessage}}
                </div>
            <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
        </div>

    </div>
</div>

    <div  class="modal" id="openModal">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content"> 
                <div class="modal-header">
                    <h5 class="modal-title">Seleccionar vehículo</h5>
                </div>
                <div class="modal-body">
                    <div v-if="loadingVehicles" class="d-flex justify-content-center">
                        <div class="spinner-border" role="status"></div>
                    </div>

                    <div v-else>
                        <div  class="row g-0 mb-2 justify-content-between">
                            <div class="col-7">
                                <div class = "input-group input-group-sm mb-0 " >
                                    <input v-model="search"  placeholder="Buscar vehículo" type="search" class="form-control form-control-sm" @input="cancel()"/>
                                    <div class="input-group-append">
                                        <button class="btn btn-secondary btn-sm" type="button" @click="searchVehiculo(search)"><i class="bi bi-search"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto" v-show="!loadingVehicles && totalPaginasModal>1">
                                <nav aria-label="..." >
                                    <ul class="pagination pagination-sm justify-content-end mb-0">
                                        <li v-bind:class="{'disabled':vistaModal==1 , 'page-item': true} ">
                                          <a class="page-link" href="javascript:;" @click="vistaModal--"><i class="bi bi-chevron-double-left"></i></a>
                                        </li>

                                        <li v-for="pagina in paginasVistaModal-Math.max((vistaModal * paginasVistaModal)-totalPaginasModal, 0)" :key="pagina.id" v-bind:class="{'active': pagina+(paginasVistaModal*(vistaModal-1))==paginaActualModal, 'page-item':true} "><a class="page-link" href="javascript:;" @click="paginaActualModal = pagina+(paginasVistaModal*(vistaModal-1))">{{pagina+(paginasVistaModal*(vistaModal-1))}}</a></li>

                                        <li v-bind:class="{'disabled':vistaModal >= (totalPaginasModal/paginasVistaModal) , 'page-item': true}">
                                          <a class="page-link" href="javascript:;" @click="vistaModal++"><i class="bi bi-chevron-double-right"></i></a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <table class="table table-striped table-bordered table-sm table-hover mb-0" style="font-size:.9rem;">
                            <thead>
                                <th scope="col">Tipo</th>
                                <th scope="col">Marca</th>
                                <th scope="col">Modelo</th>
                                <th scope="col">No. Económico</th>
                                <th scope="col">VIN</th>
                                <th scope="col">Placa estatal</th>
                                <th scope="col">Placa federal</th>
                            </thead>
                            <tbody>
                                <tr v-for="vehiculo in vehiculosPaginados" v-bind:key="vehiculo" @click="vehiculoSeleccionado = vehiculo; seleccionarVehiculo(vehiculo.id_vehiculo), numeroLlantas = vehiculo.numero_llantas">
                                    <td>{{vehiculo.tipo_vehiculo}}</td>
                                    <td>{{vehiculo.marca}}</td>
                                    <td>{{vehiculo.modelo}}</td>
                                    <td>{{vehiculo.no_economico}}</td>
                                    <td>{{vehiculo.vin}}</td>
                                    <td>{{vehiculo.placa_estatal}}</td>
                                    <td>{{vehiculo.placa_federal}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                  <button type="button" id="closeModal" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                </div>
            </div>
        </div>
    </div>

    <div  class="modal" id="modalMedicion">
        <div :class="{'modal-sm':edit, 'modal-dialog modal-dialog-centered':true}">
            <div class="modal-content"> 
                <div class="modal-header">
                    <h5 class="modal-title">Editar medición</h5>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-auto" v-show="!edit">
                            <div>Posicion*</div>
                            <select :class="{'form-select form-select': true, 'is-invalid':emptyRequireds.includes(0)}" v-model="data.posicion">
                                <option selected disabled value="">Seleccionar</option>
                                <option v-for="llanta in numeroLlantas" v-bind:key="llanta" v-bind:value="llanta">{{llanta}}</option>
                            </select>
                            <div class="invalid-feedback"> La posición requerido </div>
                        </div>

                        <div class="col-auto" v-show="!edit">
                            <div>Tipo Medición*</div>
                            <select :class="{'form-select form-select':true, 'is-invalid':emptyRequireds.includes(1) }" v-model="data.id_tipo_medicion">
                                <option selected disabled value="">Seleccionar</option>
                                <option v-for="tipo in tiposMedicion" v-bind:key="tipo" v-bind:value="tipo.id_tipo_medicion">{{tipo.nombre}}</option>
                            </select>
                            <div class="invalid-feedback"> El tipo de medición </div>
                        </div> 
                        
                        <div :class="{'col-4':!edit, 'col': edit }">
                            <div>Medición*</div>
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <input type="number"  :class="{'no-spin form-control m-input': true, 'is-invalid':emptyRequireds.includes(2)}" v-model="data.valor" >
                                    <div class="invalid-feedback"> El valor es requerido </div>
                                </div>
                                <div class="col-4">
                                    <label>{{data.id_tipo_medicion == 1?'mm': data.id_tipo_medicion == 2 ?'psi':''}}</label>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                  <button type="button" id="closeModalMed" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                  <button type="button" id="saveButton" class="btn btn-success" :disabled="hasChanged" @click="upload()">Guardar {{edit ? 'cambios' : 'nueva medición'}}</button>
                </div>
            </div>
        </div>
    </div>

    <div  class="modal" id="modalEliminar">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content"> 
                <div class="modal-header">
                    <h5 class="modal-title">Eliminar medición</h5>
                </div>
                <div class="modal-body">
                    ¿Está seguro que desea eliminar el registro?
                </div>

                <div class="modal-footer">
                  <button type="button" id="closeModalDel" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                  <button type="button" id="saveButton" class="btn btn-success" @click="deleteMedicion(delId); delId = null">Eliminar</button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import vehiculosService from '../services/vehiculosService.js'
import medicionesService from '../services/medicionesService.js'
export default {
    name: 'Mediciones',
    data() {
        return {
            fechaInicial: null,
            fechaFinal: null,
            today:null,

            loadingVehicles: false,
            loading: false,
            vehiculoSeleccionado: null,
            vehiculos: [],
            mediciones:[],
            tiposMedicion:[],
            emptyRequireds:[],
            numeroLlantas:null,
            posicion:"",
            tipoMedicion:"",
            tableLoaded:false,
            edit:false,

            oldValue: null,
            idMedicion: null,
            idVehiculo:null,

            tableHeaders:[
                ['fecha_reg','Fecha',true],
                ['tipo_medicion','Tipo',true],
                ['serie','Serie llanta',true],
                ['posicion','Posición',true],
                ['valor','Medición',true],
                ['usuario_reg','Usuario',true],
                ['fecha_mod','Fecha de modificación',false],
                ['usuario_mod','Modificó',false],
            ],
            orden:'asc',
            currentColumn:null,
            search:'',
            searched:false,
            delId: null,

            alertMessage:'',

            data:{
                id_vehiculo: null,
                id_tipo_medicion: null,
                posicion: null,
                valor: '',
                id_usuario_mod: localStorage.getItem('user_id')
            },
            oldData:{
                id_vehiculo: null,
                id_tipo_medicion: null,
                posicion: null,
                valor: '',
                id_usuario_mod: localStorage.getItem('user_id')
            },

            elementosPagina: 16,
            paginaActual: 1,
            elementosPaginaModal: 12,
            paginaActualModal: 1,

            paginasVista:10,
            vista:1,
            paginasVistaModal:8,
            vistaModal:1,

        }
    },
    methods:{
        async searchVehiculo(search){
            this.loadingVehicles = true;
            await this.vehiculosService.searchVehiculo(search).then(data=>this.vehiculos = data);
            this.searched = true;
            this.loadingVehicles = false;
        },

        seleccionarVehiculo(idVehiculo){
            document.getElementById('closeModal').click();
            this.paginaActual = 1;
            this.idVehiculo = idVehiculo; 
            this.loadData(idVehiculo);
            this.oldData.id_vehiculo = this.data.id_vehiculo = idVehiculo;
            this.tipoMedicion = ""; 
            this.posicion="";
        },
        async loadData(idVehiculo){
            this.loading = true;
            await this.medicionesService.readMedicionId(idVehiculo).then(data=> this.mediciones = data);
            this.ordenar(this.currentColumn, 0);
            this.tableLoaded = true;
            setTimeout(() => {
                const height = this.$refs.containerRef.clientHeight;
                this.elementosPagina = parseInt((height - 30)/30.6);
                console.log(height, this.elementosPagina);
            }, 20);
            this.loading = false;
        },

        async upload(){
            this.checkRequiredValues();
            if(this.emptyRequireds.length === 0){
                document.getElementById('closeModalMed').click();
                this.loading = true;
                try{
                    if(this.edit){
                        await this.medicionesService.updateMedicion(this.idMedicion, this.data);
                    }else{
                        await this.medicionesService.createMedicion(this.data);
                    }
                    await this.loadData(this.idVehiculo);
                    this.alertMessage = this.edit? 'Actualizado con exito':'Creado con exito';
                }
                catch(e){
                    this.alertMessage = e.response.data.message;
                    var toast = document.querySelector("#toastAlert");
                    toast.classList.remove("bg-secondary");
                    toast.classList.add("bg-danger");
                }
            }
                this.loading = false;
                this.showAlert();
        },

        checkRequiredValues(){
                this.emptyRequireds = []; 
                if(this.data.posicion == ''){
                    this.emptyRequireds.push(0);
                }
                if(this.data.id_tipo_medicion == ''){
                    this.emptyRequireds.push(1);
                }
                if(this.data.valor== ''){
                    this.emptyRequireds.push(2);
                }
         },

        async deleteMedicion(id){
            document.getElementById('closeModalDel').click();
            this.loading = true;
            try{
                await this.medicionesService.deleteMedicion(id);
                await this.loadData(this.idVehiculo);
                this.alertMessage = 'Eliminado con exito';
            } catch(e){
                this.alertMessage = e.response.data.message;
                var toast = document.querySelector("#toastAlert");
                toast.classList.remove("bg-secondary");
                toast.classList.add("bg-danger");
            }
            this.showAlert();

            if(this.paginaActual>this.totalPaginas){
                this.paginaActual=this.totalPaginas; 
            }
        },

        initializeValues(){
            this.data["id_usuario_reg"] = this.data.id_usuario_mod;
            this.oldData["id_usuario_reg"] = this.data.id_usuario_mod;
            this.data["manual"] = true;
            this.oldData["manual"] = true;

            this.oldData.id_tipo_medicion = this.data.id_tipo_medicion = '';
            this.oldData.posicion = this.data.posicion = '';
            this.oldData.valor = this.data.valor = '';
        },


        showAlert(){
            if(this.loading){
                setTimeout(this.showAlert, 50);
                return;
            }

            var toast = document.querySelector("#toastAlert");
            toast.classList.add('show');
            setTimeout(() => {
                toast.classList.remove('show');
                if(toast.classList.contains("bg-danger")){
                  toast.classList.remove("bg-danger");
                  toast.classList.add("bg-secondary");
                }
            }, 3000);
        },

        editar(medicion){
            if(this.data.hasOwnProperty('id_usuario_reg')){
                delete this.data['id_usuario_reg'];
                delete this.oldData['id_usuario_reg'];
                delete this.data['manual'];
                delete this.oldData['manual'];
            }
            this.oldValue = this.data.valor=medicion.valor.slice(0, -2);
            this.data.posicion = medicion.posicion;
            this.data.id_tipo_medicion = this.tiposMedicion.find(t=> t.nombre == medicion.tipo_medicion).id_tipo_medicion;
            this.idMedicion=medicion.id_medicion;
        },

        inicializarFecha(){
            this.today = new Date();
        var dd = this.today.getDate();
        var mm = this.today.getMonth() + 1;
        var aaaa = this.today.getFullYear();
        var dd2, mm2, aaaa2

        dd2 = dd - 14;
        mm2 = mm;
        aaaa2 = aaaa;
        if(dd<14){
            mm2 = mm-1
            dd2= new Date(aaaa, mm2, 0).getDate()+dd-14;
            if(mm == 1){
                mm2=12;
                aaaa2 = aaaa2-1
                dd2= new Date(aaaa2, mm2, 0).getDate()+dd-14;
            }
            
        } 
        mm = String(mm).padStart(2, '0'); 
        mm2 = String(mm2).padStart(2, '0'); 
        dd = String(dd).padStart(2, '0'); 
        dd2 = String(dd2).padStart(2, '0'); 
        
        this.today = aaaa + '-' + mm + '-' + dd;
        this.fechaFinal = this.today
        this.fechaInicial = aaaa2 + '-' + mm2 + '-' + dd2;
        },
        ordenar(elemento, auto){
            if (auto == null){
                if (this.currentColumn === elemento){
                    this.currentColumn = this.orden ==='desc'? null:elemento;
                    this.orden = this.orden === 'asc'?'desc':'asc'; 
                }else{
                    this.orden = 'asc';
                    this.currentColumn = elemento;
                }
            }
            this.mediciones.sort(this.compare);
            
            
        },
        compare(a,b){
                var value = this.currentColumn??'fecha_reg';
                const varA = typeof a[value] === "string"
                      ? a[value].toUpperCase() : a[value];
                const varB = typeof b[value] === "string"
                      ? b[value].toUpperCase() : b[value];
                let comparision = 0;
                if (varA > varB) comparision = 1;
                else if (varA < varB) comparision = -1;
                if(value === 'fecha_reg') comparision = -comparision;
                return this.orden === "desc" ? comparision * -1 : comparision;
        },
        formatDate(date){
            if(date){
            var date = new Date(date);
            var aaaa = date.getFullYear();
            var mm = String(date.getMonth() + 1).padStart(2,'0');
            var dd = String(date.getDate()).padStart(2,'0');
            var hh = String(date.getHours()).padStart(2,'0');
            var Mm = String(date.getMinutes()).padStart(2,'0');
            var ss = String(date.getSeconds()).padStart(2,'0');
            return (dd + '-' + mm + '-' + aaaa + ' ' + hh + ':'+ Mm);
            } 
            return '';
        },
        async cancel(){
            if(!this.search && this.searched){
                this.loadingVehicles = true;
                await this.vehiculosService.readVehiculos().then(data=>this.vehiculos = data);
                this.loadingVehicles = false;
                
                this.searched = false;
            }
        }
    },
    computed:{
        filtroMediciones(){
            var medicionesFiltradas;
            medicionesFiltradas =  this.mediciones.filter(m=> new Date(m.fecha_reg.split(' ')[0]).setHours(0, 0, 0, 0)>= new Date(this.fechaInicial).setHours(0, 0, 0, 0) && 
            new Date(m.fecha_reg.split(' ')[0]).setHours(0, 0, 0, 0)<= new Date(this.fechaFinal).setHours(0, 0, 0, 0));
            medicionesFiltradas = this.tipoMedicion? medicionesFiltradas.filter(m => m.tipo_medicion == this.tiposMedicion.find(t=> t.id_tipo_medicion == this.tipoMedicion).nombre):medicionesFiltradas;
            medicionesFiltradas = this.posicion? medicionesFiltradas.filter(m => m.posicion == this.posicion):medicionesFiltradas;

            return medicionesFiltradas;
        },
        vehiculosPaginados(){
            let ini = (this.paginaActualModal * this.elementosPaginaModal) - this.elementosPaginaModal;
            let fin = (this.paginaActualModal * this.elementosPaginaModal);
            return this.vehiculos.slice(ini, fin);
        },
        medicionesPaginadas(){  
            if(this.tableLoaded && this.paginaActual>this.totalPaginas && this.totalPaginas>0){
                this.paginaActual = this.totalPaginas;
            } 
            let ini = (this.paginaActual * this.elementosPagina) - this.elementosPagina;
            let fin = (this.paginaActual * this.elementosPagina);
            return this.filtroMediciones.slice(ini, fin);
        },
        totalPaginas(){
            return Math.ceil(this.filtroMediciones.length / this.elementosPagina)
            
        },
        totalPaginasModal(){
            return Math.ceil(this.vehiculos.length / this.elementosPaginaModal)
        },
        hasChanged(){
            if(this.edit && this.data.valor !== this.oldValue){
                return false;
            }else if(JSON.stringify(this.data)!== JSON.stringify(this.oldData) ){
                return false;
            }
            return true;
        },
        
    } ,
    vehiculosService: null,
    medicionesService: null,

    created(){
    this.vehiculosService = new vehiculosService;
    this.medicionesService = new medicionesService;
   },
    async mounted(){
        this.inicializarFecha();
        this.loading = true;
        await this.vehiculosService.readVehiculos().then(data=>this.vehiculos = data);
        await this.medicionesService.readTiposMedicion().then(data=> this.tiposMedicion = data);
        if(this.$route.params.id_vehiculo){
            this.data.id_vehiculo = this.oldData.id_vehiculo = this.$route.params.id_vehiculo;
            this.idVehiculo = this.$route.params.id_vehiculo;
            this.vehiculoSeleccionado = this.vehiculos.find(v=> v.id_vehiculo == this.idVehiculo);
            this.numeroLlantas = this.vehiculoSeleccionado.numero_llantas
            this.loadData(this.idVehiculo);
        }
        this.loading = false;
        
    }
}
</script>

<style scoped>
    .table-hover tr:hover {
        background:#0033ff41;
        cursor: pointer;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    th:hover{
        cursor: pointer;
    }
    .m-input { 
    text-align: right; 
    }

    input::-webkit-calendar-picker-indicator {
      cursor: pointer;
    }

    h5{
        font-size: 1.1em;
    }

    input[type="search"]::-webkit-search-cancel-button:hover {
        cursor: pointer;
    }

</style>