import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { createApp } from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { createPopper } from '@popperjs/core';
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import storage from './storage'
axios.defaults.baseURL = 'https://llantas.apicontrol.website/api/v1/'

var token = localStorage.getItem('token');
if(token){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

axios.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    if (error.response.status === 401) {
        localStorage.setItem('isAuthenticated', false);
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        router.push('/login')
        console.log('No autenticado')
    }
    return Promise.reject(error)
  })

createApp(App).use(VueAxios, axios).use(router).use(storage).mount('#app')
