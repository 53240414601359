<template>
    <div class="container-fluid h-100">
        <div class="h-100 d-flex flex-column">
            <div class="row align-items-center justify-content-between px-0 g-0 mb-2">
                <div class="col-auto">
                    <h4>Movimientos</h4>
                </div>

                <div class="col-auto">
                    <button type="button" class="btn btn-success btn-sm mt-auto" @click="seleccionV = 1;openListaVehiculos()">Seleccionar vehículo...</button>
                </div>
            </div>


            <div v-show="loading" class="over-layed">
                <div class="spinner-border spinner-lg" role="status"></div>
            </div>


            <div class="row g-0 mt-0 justify-content-between rounded p-2" v-show="dataLoaded" style="background-color: lightGrey;">
                <div class="col-auto">
                    <h5 class="mb-0">
                        {{vehiculoSeleccionado?(vehiculoSeleccionado.tipo_vehiculo + '  ' + vehiculoSeleccionado.marca + '    ' + vehiculoSeleccionado.modelo):''}}
                    </h5>
                </div>
                <div class="col-auto">
                    <h5 class="mb-0">{{vehiculoSeleccionado?'No. económico ' + vehiculoSeleccionado.no_economico:''}}</h5>
                </div>
                <div class="col-auto">
                    <h5 class="mb-0">{{vehiculoSeleccionado?'Placa estatal: ' + vehiculoSeleccionado.placa_estatal:''}}</h5>
                </div>
                <div class="col-auto">
                    <h5 class="mb-0">{{vehiculoSeleccionado?(vehiculoSeleccionado.placa_federal?'Placa federal: ' + vehiculoSeleccionado.placa_federal:''):''}}</h5>
                </div>
            </div>


            <div class="bg-white shadow p-4 mt-3 flex-grow-1 container-fluid h-100 d-flex flex-column" v-if="dataLoaded">
                <div class="row g-2 rounded">
                    <div class="col-auto d-inline my-0">
                        <label for="tipo-movimiento-select" class="d-inline-block">Movimiento</label>&nbsp;
                        <select class="form-select form-select-sm d-inline-block" v-model="tipoMovimiento" id="tipo-movimiento-select" style="width: auto" @change="movimientoSelect()">
                            <option selected disabled value="">Seleccione tipo</option>
                            <option v-for="tipo in tiposMovimiento" v-bind:key="tipo" v-bind:value="tipo.id_tipo_movimiento">{{tipo.nombre}}</option>
                        </select>
                    </div>

                    <div class="col-auto  my-0" v-show="tipoMovimiento==4">
                        <button class="btn btn-sm btn-secondary" @click="seleccionV = 2;openListaVehiculos()">Seleccionar vehículo 2</button>
                    </div>
                    <div class="col-auto  my-0" v-show="tipoMovimiento!='' && tipoMovimiento!=1">
                        <button class="btn btn-sm btn-success" @click="guardarMovimiento()" :disabled="saveMovimientoButton">Realizar movimiento</button>
                    </div>
                </div>

                <hr class="mt-2">

                <div class="row align-items-center justify-content-around px-0 g-0 flex-grow-1">
                    <div :class="{'d-flex flex-column h-100':true ,'col-6 self-align-center': tipoMovimiento === 4 && vehiculoSeleccionado2, 'col-3': !vehiculoSeleccionado2}">
                        <div class="row justify-content-around text-center flex-grow-1">
                            <div :class="{'align-self-center col-6': tipoMovimiento === 4 && vehiculoSeleccionado2, 'align-self-start': tipoMovimiento !== 4 && !vehiculoSeleccionado2}">
                                <img v-if="vehiculoSeleccionado" :src="vehiculoSeleccionado.layout" :class="{'img-fluid p-4':true, 'full-img':tipoMovimiento !== 4 || !vehiculoSeleccionado2, 'half-img':tipoMovimiento === 4 && vehiculoSeleccionado2}">
                                <h6 v-show="tipoMovimiento === 4 && vehiculoSeleccionado2">Vehículo 1</h6>
                            </div>

                            <div :class="{'col-6':true ,'align-self-center': tipoMovimiento === 4 && vehiculoSeleccionado2}" v-show="tipoMovimiento === 4 && vehiculoSeleccionado2">
                                <img v-if="vehiculoSeleccionado2" :src="vehiculoSeleccionado2.layout" class="img-fluid p-4 half-img">
                                <h6 v-show="tipoMovimiento === 4">Vehículo 2</h6>
                            </div>
                        </div>
                    </div>
                    
                    <div :class="{'align-self-start h-100':true, 'col-6 justify-content-between': tipoMovimiento === 4 && vehiculoSeleccionado2, 'col-9': !vehiculoSeleccionado2}" ref="tableRef">
                        <div :class="{'row':true,'h-40': tipoMovimiento === 4 && vehiculoSeleccionado2}">
                            <h6 class="mb-0">Vehículo 1</h6>
                            <div :class="{'table-wrapper-scroll-y my-custom-scrollbar':true, 'table-height': tipoMovimiento === 4 && vehiculoSeleccionado2}">
                                <table v-show="dataLoaded" :class="{'table table-sm table-bordered':true, 'table-hover': llantasVehiculo.length>0}" style="white-space: nowrap;">
                                    <thead>
                                        <th scope="col" v-for="header in tableHeaders" :key="header">{{header[1]}}</th>
                                        <th scope="col">Vida útil</th>
                                    </thead>
                                    <tbody>
                                        <tr v-if="llantasVehiculo.length<1" >
                                            <td align="center" colspan="8">El vehículo no cuenta con llantas registradas</td>
                                        </tr>
                                        <tr v-else v-for="llantaVehiculo in llantasVehiculo" v-bind:key="llantaVehiculo" @click="seleccionLlanta(llantaVehiculo)" :class="{'bg-primary':llantaVehiculoSeleccionada === llantaVehiculo || llantaVehiculoSeleccionada2 === llantaVehiculo}">
                                                <td v-for="header in tableHeaders" :key="header">{{llantaVehiculo[header[0]]}} </td>
                                                <td :class="colorVidaUtil(llantaVehiculo.vida_util)">{{vidaUtil(llantaVehiculo.vida_util)}}</td>
                                        </tr>
                                    </tbody>   
                                </table>
                            </div>
                        </div>
                    
                        <div v-if="tipoMovimiento === 4 && vehiculoSeleccionado2" class="row h-40">
                            <h6 class="mb-0 pt-4">Vehículo 2: {{vehiculo2String}}</h6>
                            <div class="table-wrapper-scroll-y my-custom-scrollbar table-height">
                                
                                <table :class="{'table table-sm table-bordered':true, 'table-hover': llantasVehiculo2.length>0}" style="white-space: nowrap;">
                                    <thead>
                                        <th scope="col" v-for="header in tableHeaders" :key="header">{{header[1]}}</th>
                                        <th scope="col">Vida útil</th>
                                    </thead>

                                    <tbody>
                                        <tr v-if="llantasVehiculo2.length<1" >
                                            <td align="center" colspan="8">El vehículo no cuenta con llantas registradas</td>
                                        </tr>
                                        <tr v-else v-for="llantaVehiculo2 in llantasVehiculo2" v-bind:key="llantaVehiculo2" @click="seleccionLlanta(llantaVehiculo2)" :class="{'bg-primary':llantaVehiculoSeleccionada === llantaVehiculo2 || llantaVehiculoSeleccionada2 === llantaVehiculo2}">
                                            <td v-for="header in tableHeaders" :key="header">{{llantaVehiculo2[header[0]]}}</td>
                                            <td :class="colorVidaUtil(llantaVehiculo2.vida_util)">{{vidaUtil(llantaVehiculo2.vida_util)}}</td>
                                        </tr>
                                    </tbody>   
                                </table>
                                </div>
                        </div>
                    </div>
                </div>       
            </div>

            <div ref="toastRef" id="toastAlert" class="toast bg-secondary text-white position-fixed bottom-0 end-0 m-3" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="d-flex">
                    <div class="toast-body">
                        {{alertMessage}}
                    </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
            </div>
        </div>
    </div>


    <ListaVehiculos ref="listaVehiculosRef" @seleccionVehiculo="seleccionVehiculo" :omitirVehiculo="omitirVehiculo"/>
    <LlantaVehiculoForm :vehiculo="vehiculoSeleccionado" :posicionesOcupadas="posicionesOcupadas" :dataMovimiento="dataMovimiento" ref="llantasFormRef" @close="onFormClose" />


    

</template>

<script>
import { Modal } from 'bootstrap'
import { useRoute } from 'vue-router';
import {computed, ref, onMounted} from 'vue'
import ListaVehiculos from '../components/lista-vehiculos.vue'
import llantasService from '../services/llantasService.js'
import llantasVehiculoService from '@/services/llantasVehiculoService';
import LlantaVehiculoForm from '../components/llanta-vehiculo-form.vue'
import movimientosService from '../services/movimientosService.js'

export default {
    name:'Movimientos',
    components:{ListaVehiculos, LlantaVehiculoForm},
    setup(){    
        const alertMessage = ref(null);
        const vehiculoSeleccionado = ref( null);
        const vehiculoSeleccionado2 = ref( null);
        const vehiculos = ref([]);
        const tiposMovimiento = ref([]);
        const llantasVehiculo = ref([]);
        const llantasVehiculo2 = ref([]);
        const omitirVehiculo = ref(null);
        const seleccionV = ref(1);
        const toastRef = ref(null);
        const tableRef = ref(null);
        const vehiculo2String = ref('');
        const route = useRoute();
        const height = ref(0);
        
        const tipoMovimiento = ref('');
        const posicionesOcupadas = ref([]);
        const dataLoaded = ref(false);
        const listaVehiculosRef = ref(null);
        const llantasFormRef = ref(null);
        const llantaVehiculoSeleccionada = ref(null)
        const llantaVehiculoSeleccionada2 = ref(null)
        const dataMovimiento = ref({
                'id_tipo_movimiento': null,
                'id_llanta': null,
                'id_llanta_cambio': null,
                'id_usuario': localStorage.getItem('user_id'),
                'id_vehiculo_origen': null,
                'id_vehiculo_destino':null,
                'posicion_origen':null,
                'posicion_destino':null,
                'descripcion':null
            });

        const tableHeaders = [
            ['posicion', 'Posición'],
            ['serie', 'Serie'],
            ['marca', 'Marca'],
            ['modelo', 'Modelo'],
            ['dimension', 'Dimensión'],
            ['rin', 'Rin'],
            ['desgaste', 'Desgaste'],
            ['presion', 'Presión'],
            ['vida_util', 'Vida útil']
        ]
        
        const movimientos_service =ref( new movimientosService);
        const llantas_vehiculo_service =ref( new llantasVehiculoService);
        const llantas_service =ref( new llantasService);
        
        const loading = ref(false);

        onMounted(async () => {
        if(route.params.vehiculo){
            vehiculoSeleccionado.value = JSON.parse(route.params.vehiculo);
            dataMovimiento.value.id_vehiculo_origen = vehiculoSeleccionado.value.id_vehiculo;
            loadData(vehiculoSeleccionado.value.id_vehiculo);
        }
        await movimientos_service.value.readTiposMovimiento().then(data=>tiposMovimiento.value = data);
        });

        const  openListaVehiculos =  ()=>{
            if(seleccionV.value === 1){
                omitirVehiculo.value=null
            };
            listaVehiculosRef.value.open();
        }

        const saveMovimientoButton = computed(()=>{
            if((tipoMovimiento.value === 3 || tipoMovimiento.value === 4) && llantaVehiculoSeleccionada2.value===null){
                return true;
            }
            if(llantaVehiculoSeleccionada.value===null){
                return true;
            }
            return false;
        })

        function colorVidaUtil(vidaUtil){
            if(vidaUtil<150){
                return 'text-danger';
            }else if(vidaUtil<300){
                return 'text-warning';
            }   
            return '';
        }

        function vidaUtil(vidaUtil){
            if (vidaUtil){
                return vidaUtil + ' días';
            }
            return '';
        }

        async function loadData(idVehiculo){
            loading.value= true
            await llantas_vehiculo_service.value.readLlantasVehiculoId(idVehiculo).then(data=> llantasVehiculo.value = data);
            usedPosiciones();
            loading.value=false;
            dataLoaded.value = true;
            setTimeout(() => {
                height.value = tableRef.value.clientHeight;
                console.log('height')
                console.log('height', height.value);
                let elements = document.getElementsByClassName('table-height');
                console.log(elements);
                for (let i = 0; i < elements.length; i++) {
                    let element = elements[i];
                    element.style = 'height:'+String(parseInt(height.value/2))+'px;';
                }
                console.log(elements);
            }, 20);
            
        };

        function seleccionLlanta(llantaVehiculo){
            if(tipoMovimiento.value!=='' && tipoMovimiento.value!==3 && tipoMovimiento.value!==4){
                if(llantaVehiculoSeleccionada.value === llantaVehiculo){
                    llantaVehiculoSeleccionada.value = null;
                }else{
                    llantaVehiculoSeleccionada.value = llantaVehiculo;
                }
            }
            if(tipoMovimiento.value === 3){
                if(llantaVehiculoSeleccionada.value === llantaVehiculo){
                    llantaVehiculoSeleccionada.value = null;
                }
                else if(llantaVehiculoSeleccionada2.value === llantaVehiculo){
                    llantaVehiculoSeleccionada2.value = null;
                }
                else if(llantaVehiculoSeleccionada.value==null){
                    llantaVehiculoSeleccionada.value = llantaVehiculo;
                }else{
                    llantaVehiculoSeleccionada2.value = llantaVehiculo;
                }
            }

            if(tipoMovimiento.value === 4){   
                if(llantaVehiculoSeleccionada.value === llantaVehiculo){
                    llantaVehiculoSeleccionada.value = null;
                }
                else if(llantaVehiculoSeleccionada2.value === llantaVehiculo){
                    llantaVehiculoSeleccionada2.value = null;
                }
                else if(llantaVehiculo.id_vehiculo === vehiculoSeleccionado.value.id_vehiculo){
                    llantaVehiculoSeleccionada.value = llantaVehiculo;
                }
                else{
                    llantaVehiculoSeleccionada2.value = llantaVehiculo;
                }
                
            }
        }

        function clearData(){
                dataMovimiento.value.id_tipo_movimiento =  null,
                dataMovimiento.value.id_llanta =  null,
                dataMovimiento.value.id_llanta_cambio =  null,
                dataMovimiento.value.id_vehiculo_origen =  null,
                dataMovimiento.value.id_vehiculo_destino = null,
                dataMovimiento.value.posicion_origen = null,
                dataMovimiento.value.posicion_destino = null,
                dataMovimiento.value.descripcion = null
        }

        function usedPosiciones(){
            posicionesOcupadas.value=[];
            for(let i=0;i<llantasVehiculo.value.length;i++){
                if(llantasVehiculo.value[i].posicion != null){
                    posicionesOcupadas.value.push(llantasVehiculo.value[i].posicion);
                }
            }
        }

        function movimientoSelect(){
            clearData();
            llantaVehiculoSeleccionada2.value = null;
            vehiculoSeleccionado2.value = null;
            if(tipoMovimiento.value === 1){
                llantaVehiculoSeleccionada.value = null;
                dataMovimiento.value.id_tipo_movimiento = 1;
                dataMovimiento.value.id_vehiculo_destino = vehiculoSeleccionado.value.id_vehiculo;
                llantasFormRef.value.open();
            }else if(tipoMovimiento.value === 4){
                omitirVehiculo.value = vehiculoSeleccionado.value.id_vehiculo;
            }    
        };

        async function seleccionVehiculo(vehiculo){
            let params = ['tipo_vehiculo', 'marca', 'modelo', 'no_economico', 'placa_estatal', 'placa_federal']
            if(seleccionV.value === 2){
                vehiculoSeleccionado2.value = vehiculo;
                vehiculo2String.value = '';

                for (let index = 0; index < params.length; index++) {
                    vehiculo2String.value += vehiculo[params[index]] == null? '' 
                        :index === 3? ', No.economico ': index === 4? ', Placa Estatal ' : index === 5? ', Placa Federal ': '';
                    vehiculo2String.value += vehiculo[params[index]]?? '';
                    vehiculo2String.value+= ' ';
                }

                loading.value= true
                await llantas_vehiculo_service.value.readLlantasVehiculoId(vehiculoSeleccionado2.value.id_vehiculo).then(data=> llantasVehiculo2.value = data);
                setTimeout(() => {
                    let elements = document.getElementsByClassName('table-height');
                    console.log(elements);
                    for (let i = 0; i < elements.length; i++) {
                        let element = elements[i];
                        element.style = 'height:'+String(parseInt(height.value/2)-40)+'px;';
                    }
                    console.log(elements);
                }, 20);
                loading.value=false;
            }else{
                tipoMovimiento.value = '';
                vehiculoSeleccionado.value = vehiculo;
                vehiculoSeleccionado2.value = null;
                loadData(vehiculo.id_vehiculo);
            }
        };

        async function guardarMovimiento(){
            dataMovimiento.value.id_tipo_movimiento = tipoMovimiento.value;
            dataMovimiento.value.id_llanta = llantaVehiculoSeleccionada.value.id_llanta;
            dataMovimiento.value.posicion_origen = llantaVehiculoSeleccionada.value.posicion;
            dataMovimiento.value.id_vehiculo_origen = vehiculoSeleccionado.value.id_vehiculo;
            
            
            try{
                loading.value = true;
                switch(tipoMovimiento.value){
                    case(2):
                        dataMovimiento.value.posicion_destino = null;
                        break;
                    case(3):
                        dataMovimiento.value.id_vehiculo_destino = dataMovimiento.value.id_vehiculo_origen;
                        dataMovimiento.value.posicion_destino = llantaVehiculoSeleccionada2.value.posicion
                        dataMovimiento.value.id_llanta_cambio = llantaVehiculoSeleccionada2.value.id_llanta
                        break;
                    case(4):
                        dataMovimiento.value.id_vehiculo_destino = vehiculoSeleccionado2.value.id_vehiculo;
                        dataMovimiento.value.posicion_destino = llantaVehiculoSeleccionada2.value.posicion
                        dataMovimiento.value.id_llanta_cambio = llantaVehiculoSeleccionada2.value.id_llanta
                        break;
                    case(5):
                        dataMovimiento.value.posicion_destino = null;
                        await llantas_service.value.updateLlanta(llantaVehiculoSeleccionada.value.id_llanta, {'activo':false})
                        break;
                }
                await movimientos_service.value.createMovimiento(dataMovimiento.value);
                loadData(vehiculoSeleccionado.value.id_vehiculo);
                if(tipoMovimiento.value === 4){
                    await llantas_vehiculo_service.value.readLlantasVehiculoId(vehiculoSeleccionado2.value.id_vehiculo).then(data=> llantasVehiculo2.value = data);        
                }
                alertMessage.value = 'El movimiento se ha generado cone éxito.';
            }catch(e){
                alertMessage.value = e.response.data.error;
                toastRef.value.classList.remove("bg-secondary");
                toastRef.value.classList.add("bg-danger");
            }
            llantaVehiculoSeleccionada.value = null;
            llantaVehiculoSeleccionada2.value = null;
            clearData()
            showAlert();
        }

        function showAlert(){
            if(loading.value){
                setTimeout(showAlert, 50);
                return;
            }

            toastRef.value.classList.add('show');
            setTimeout(() => {
                toastRef.value.classList.remove('show');
                if(toastRef.value.classList.contains("bg-danger")){
                  toastRef.value.classList.remove("bg-danger");
                  toastRef.value.classList.add("bg-secondary");
                }
            }, 3000);
        }

        function onFormClose(modificado){
            tipoMovimiento.value = ''; 
            if(modificado){loadData(vehiculoSeleccionado.value.id_vehiculo)};
        }

        return{
            alertMessage,
            dataLoaded,
            dataMovimiento,
            guardarMovimiento,
            listaVehiculosRef,
            llantasFormRef,
            llantasVehiculo,
            llantasVehiculo2,
            llantaVehiculoSeleccionada,
            llantaVehiculoSeleccionada2,
            loadData,
            loading,
            movimientoSelect,
            omitirVehiculo,
            onFormClose,
            openListaVehiculos,
            posicionesOcupadas,
            saveMovimientoButton,
            seleccionLlanta,
            seleccionV,
            seleccionVehiculo,
            tipoMovimiento,
            tiposMovimiento,
            vehiculos,
            vehiculoSeleccionado,
            vehiculoSeleccionado2,
            toastRef,
            tableHeaders,
            vehiculo2String,
            colorVidaUtil,
            vidaUtil,
            tableRef,
        }
    }
}
</script>

<style>

    .table-hover tr:hover {
        background:#0033ff41;
        cursor: pointer;
    }

    input[type="search"]::-webkit-search-cancel-button:hover {
        cursor: pointer;
    }

    .my-custom-scrollbar {
        position: relative;
        height: 100%;
        overflow: auto;
    }
        .table-wrapper-scroll-y {
        display: block;
    }

    .table-height{
        height: 150px;
    }
    .half-img{
        width: 90%;
    }
    .full-img{
        width: 90%;
    }
</style>


