import axios from 'axios'

export default class vehiculosService{
    createVehiculo(data){
        return axios.post('/vehiculo',data).then(response=>[response.status, response.statusText]);
    }
    readVehiculos(){
        return axios.get('/listaVehiculoAdmin').then(response=> response.data.data);
    }
    updateVehiculo(id,data){
        return axios.put( `/vehiculo/${id}`,data).then(response=>[response.status, response.statusText]);
    }
    deleteVehiculo(id){
        return axios.delete( `/vehiculo/${id}`).then(response=>[response.status, response.statusText]);
    }
    searchVehiculo(search){
        return axios.get( '/busquedaVehiculoAdmin', {
            params: {
              search: search
            }}).then(response=> response.data.data);
    }
}